// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Group, Checkbox, Box, Accordion, Stack, Text, Loader } from '@mantine/core';
import { FC, useState, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { openModal } from '@mantine/modals';
import { ContactGroupS } from '../../../Service/ContactGroupS';
import { Contact, ContactS } from '../../../Service/ContactS';
import { ContactGroup, ServiceProviderFilter } from '../../../Types/AuthT';
import { LocationDto } from '../../../Types/LocationT';
import { LocationS } from '../../../Service/LocationS';
import { useServiceProdiderDistances } from '../../../Service/hooks/useServiceProvidersDistance';
import SelectCreateContactModal from '../../../Organismns/Contact/SelectCreateContactModal';
import { ConstantS } from '../../../Service/ConstantS';
import { useServiceProdiderAnswers } from '../../../Service/hooks/useServiceProviderAnswers';
import { RequestAnswerIcon } from '../../../Atoms/StateIcon';
import CacheAvailabilityDot from './Availability/ContactAvailabilityDot';
import ServiceProviderDistance from './Distance';

interface Props {
  eventId: number;
  group: ContactGroup;
  selectedContacts: number[];
  selectedEventDates: number[];
  toggleContact: (contactId: number) => void;
  eventLocation?: LocationDto;
  toggleMultiple: (contactIds: number[], remove: boolean) => void;
  filter: ServiceProviderFilter;
  redGreenWeakness: boolean;
}

const RecommendationGroup: FC<Props> = ({
  eventId,
  group,
  selectedContacts,
  selectedEventDates,
  toggleContact,
  eventLocation,
  toggleMultiple,
  filter,
  redGreenWeakness,
}) => {
  const { id: groupId, name } = group;
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const { radius, searchQuery } = filter;
  const { loading: distancesLoading } = useServiceProdiderDistances(contacts, eventLocation);
  const { loading: answerLoading } = useServiceProdiderAnswers(contacts, eventId, selectedEventDates);

  useEffect(() => {
    setLoading(true);
    ContactGroupS.fetchGroupContacts(groupId)
      .then(setContacts)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const showContact = (_contact: Contact) => {
    const { id: contactId, personDescription, email, distance } = _contact;
    // Show every selected contact
    if (selectedContacts.includes(contactId)) {
      return true;
    }
    // Missing email
    if (!email) {
      return false;
    }
    // Filter query exists and not matching
    if (searchQuery !== '' && !personDescription?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())) {
      return false;
    }
    if (distance && distance.distanceInMeters) {
      const distanceInKm = LocationS.metersToKm(distance.distanceInMeters);
      // Filter radius exists and not matching
      if (radius !== 300 && distanceInKm && distanceInKm > radius) {
        return false;
      }
    }
    return true;
  };

  const filteredContacts = LocationS.sortContactsByDistance(contacts.filter(showContact));
  const emptyList = filteredContacts.length === 0;
  const selectedCount = contacts.filter((_c) => selectedContacts.includes(_c.id)).length;
  const allSelected = contacts.length === selectedCount;

  if (loading) {
    return <Skeleton mb="sm" height={100} />;
  }

  if (contacts.length === 0) {
    return null;
  }

  const selectAll = () =>
    toggleMultiple(
      filteredContacts.map((c) => c.id),
      false,
    );

  const deselectAll = () => {
    toggleMultiple(
      filteredContacts.map((c) => c.id),
      true,
    );
  };

  const mapContactUI = (contact: Contact) => {
    const { id: contactId, personDescription, email, distance, requestAnswer } = contact;
    return (
      <Group position="apart" spacing="xs">
        <Checkbox
          key={personDescription}
          size="xs"
          label={
            <Group spacing="xs" mb="xs">
              {selectedEventDates.length > 0 && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {redGreenWeakness ? (
                    <RequestAnswerIcon requestAnswer={requestAnswer} size={16} color="gray" />
                  ) : (
                    <CacheAvailabilityDot requestAnswer={requestAnswer} />
                  )}
                </>
              )}
              <Text weight="bolder" sx={{ maxWidth: 170 }} className="overflow-ellipsis">
                {personDescription ?? email}
              </Text>
            </Group>
          }
          checked={selectedContacts.includes(contactId)}
          onChange={() => toggleContact(contactId)}
        />
        {eventLocation && <ServiceProviderDistance distance={distance} />}
      </Group>
    );
  };

  const openAddContactModal = () => {
    const onSelect = (contactId: number) => {
      ContactGroupS.addContact(groupId, contactId).then((_contacts) => {
        if (_contacts) {
          ContactS.fetchById(contactId).then((_contact) => {
            if (_contact) {
              setContacts([...contacts, _contact]);
            }
          });
        }
      });
    };
    openModal({
      modalId: ConstantS.Modals.SelectCreateContact,
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Kontakt hinzufügen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <SelectCreateContactModal onSelect={onSelect} omittedContacts={contacts.map((c) => c.id)} />,
    });
  };

  return (
    <Accordion.Item mb="sm" value={name} sx={(theme) => ({ backgroundColor: theme.colors.gray[0] })}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Accordion.Control>
          <Text weight="bolder" size="sm">
            {(answerLoading || distancesLoading) && <Loader mr="xs" variant="dots" size="xs" />}
            {name}{' '}
            <Text span weight="lighter" size={10}>
              {`(${selectedCount}/${filteredContacts.length})`}
            </Text>
          </Text>
        </Accordion.Control>
        {!allSelected && !emptyList && (
          <Text size={10} variant="link" color="blue" sx={{ width: 130, cursor: 'pointer' }} onClick={selectAll}>
            Gruppe auswählen
          </Text>
        )}
        {allSelected && !emptyList && (
          <Text size={10} variant="link" color="blue" sx={{ width: 130, cursor: 'pointer' }} onClick={deselectAll}>
            Gruppe abwählen
          </Text>
        )}
      </Box>
      <Accordion.Panel>
        {emptyList && (
          <Text italic size="xs">
            Die Kombination der ausgewählten Filter erzielt keine Ergebnisse.
          </Text>
        )}
        {!emptyList && <Stack spacing={0}>{filteredContacts.map(mapContactUI)}</Stack>}
        <Text mt="sm" variant="link" size="xs" color="blue" sx={{ cursor: 'pointer' }} onClick={openAddContactModal}>
          {i18n._('contactGroup.contact.button.add')}
        </Text>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default RecommendationGroup;
