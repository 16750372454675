/* eslint-disable react/jsx-props-no-spreading */
import { Button, Center, Checkbox, ColorInput, Group, Loader, Paper, Select, Text, TextInput } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { useForm } from '@mantine/form';
import { IconX } from '@tabler/icons';
import { CustomEventState } from '../../Types/EventT';
import { ConstantS } from '../../Service/ConstantS';
import { EventS } from '../../Service/EventS';
import { MixpanelS } from '../../Service/MixpanelS';
import BookingState from '../../Types/Enums/BookingState';
import { DataS } from '../../Service/DataS';
import PrimaryEmojiPicker from '../../Atoms/inputs/PrimaryEmojiPicker';
import BookingStateItem from '../../Templates/EventList/BookingStateItem';

// eslint-disable-next-line import/prefer-default-export
export const openCreateEventStatusModal = (
  refresh: () => unknown,
  existingNames: string[],
  eventState?: CustomEventState,
) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        {eventState ? 'Status bearbeiten' : 'Status erstellen'}
      </Text>
    ),
    children: <CreateEventStatusModal refresh={refresh} existingNames={existingNames} eventState={eventState} />,
  });

interface CreateModalProps {
  refresh: () => void;
  existingNames: string[];
  eventState?: Partial<CustomEventState>;
}

const CreateEventStatusModal: FC<CreateModalProps> = ({
  refresh,
  existingNames,
  eventState = {
    dummy: false,
    color: '',
    bgColor: '',
    label: '',
    originState: BookingState.OFFERED,
    useWithDefaultFilter: false,
  },
}) => {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      color: eventState.color ?? '',
      bgColor: eventState.bgColor ?? '',
      label: eventState.label ?? '',
      originState: eventState.originState ?? BookingState.OPEN,
      useWithDefaultFilter: eventState.useWithDefaultFilter ?? false,
      emoji: '',
      dummy: eventState.dummy,
    },
    validate: {
      color: (value) => (value !== '' ? null : 'Bitte Farbe eingeben'),
      bgColor: (value) => (value !== '' ? null : 'Bitte Farbe eingeben'),
      label: (value: string) => {
        if (value === '') {
          return 'Bitte Etikett eingeben';
        }
        if (existingNames.includes(value)) {
          return 'Name ist bereits vergeben';
        }
        return null;
      },
      originState: (value) => (value !== null ? null : 'Bitte Herkunftsstatus eingeben'),
    },
  });

  const { label, color, bgColor, emoji } = form.values;

  const createStatus = () => {
    setLoading(true);
    EventS.createStatus({ ...form.values })
      .then((kind) => {
        if (kind) {
          MixpanelS.track(ConstantS.TrackingEvents.EventStateCreated);
          refresh();
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={form.onSubmit(createStatus)}>
      <Group position="apart">
        <PrimaryEmojiPicker value={form.values.emoji} onChange={(val) => form.setValues({ emoji: val })} />
        {form.values.emoji && (
          <IconX size={20} onClick={() => form.setValues({ emoji: '' })} style={{ cursor: 'pointer' }} />
        )}
      </Group>
      <Text mt={5} color="dimmed" size="xs">
        Such Dir einen Emoji aus, den wir in der Eventliste an jedem Event anzeigen sollen.
      </Text>
      <TextInput mt="sm" label="Name" disabled={loading} {...form.getInputProps('label')} />
      <ColorInput
        mt="sm"
        label="Farbe wählen"
        {...form.getInputProps('color')}
        swatchesPerRow={7}
        swatches={['#F44336', '#9C27B0', '#1D5191', '#4EBF46', '#FDA632', '#FF5722', '#795548']}
      />
      <ColorInput
        mt="sm"
        label="Hintergrundfarbe"
        {...form.getInputProps('bgColor')}
        swatchesPerRow={7}
        swatches={['#FFEBEE', '#F3E5F5', '#E8EEF4', '#EDF9ED', '#FFF6EB', '#FBE9E7', '#EFEBE9']}
      />
      {eventState.color === '' && (
        <Select
          mt="sm"
          label="Basis-Buchungsstatus"
          {...form.getInputProps('originState')}
          data={DataS.getBookingStateOptions()}
        />
      )}
      <Checkbox
        size="xs"
        mt="sm"
        label="Als Standardfilter verwenden"
        {...form.getInputProps('useWithDefaultFilter', { type: 'checkbox' })}
      />
      <Text size="sm" mt="md" mb={5} color="#6E6E6E">
        Vorschau
      </Text>
      <Paper shadow="md">
        <BookingStateItem active label={label} color={color} bgColor={bgColor} onClick={() => {}} icon={emoji} />
      </Paper>
      <Center mt={40}>
        <Button
          disabled={loading || !form.isDirty()}
          type="submit"
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 230 }}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </form>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const openEditEventStatusModal = (refresh: () => void, status: CustomEventState, existingNames: string[]) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Status bearbeiten
      </Text>
    ),
    children: <EditEventStatusModal refresh={refresh} existingNames={existingNames} status={status} />,
  });

interface EditModalProps {
  refresh: () => void;
  status: CustomEventState;
  existingNames: string[];
}

const EditEventStatusModal: FC<EditModalProps> = ({ refresh, status, existingNames }) => {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      ...status,
    },
    validate: {
      color: (value) => (value !== '' ? null : 'Bitte Farbe eingeben'),
      label: (value) => {
        if (value === '') {
          return 'Bitte Etikett eingeben';
        }
        if (existingNames.includes(value)) {
          return 'Name ist bereits vergeben';
        }
        return null;
      },
      originState: (value) => (value !== null ? null : 'Bitte Herkunftsstatus eingeben'),
    },
  });

  const { label, color, bgColor, useWithDefaultFilter, emoji } = form.values;

  const updateStatus = () => {
    setLoading(true);
    EventS.editStatus(status.id ?? '', { label, color, bgColor, useWithDefaultFilter, emoji })
      .then((kind) => {
        if (kind) {
          refresh();
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={form.onSubmit(updateStatus)}>
      <Group position="apart">
        <PrimaryEmojiPicker value={form.values.emoji} onChange={(val) => form.setValues({ emoji: val })} />
        {form.values.emoji && (
          <IconX size={20} onClick={() => form.setValues({ emoji: '' })} style={{ cursor: 'pointer' }} />
        )}
      </Group>
      <Text mt={5} color="dimmed" size="xs">
        Such Dir einen Emoji aus, den wir in der Eventliste an jedem Event anzeigen sollen.
      </Text>
      <TextInput mt="sm" label="Name" {...form.getInputProps('label')} />
      <ColorInput
        mt="sm"
        label="Farbe wählen"
        {...form.getInputProps('color')}
        swatchesPerRow={7}
        swatches={['#F44336', '#9C27B0', '#1D5191', '#4EBF46', '#FDA632', '#FF5722', '#795548']}
      />
      <ColorInput
        mt="sm"
        label="Hintergrundfarbe"
        {...form.getInputProps('bgColor')}
        swatchesPerRow={7}
        swatches={['#FFEBEE', '#F3E5F5', '#E8EEF4', '#EDF9ED', '#FFF6EB', '#FBE9E7', '#EFEBE9']}
      />
      <Checkbox
        size="xs"
        mt="sm"
        label="Als Standardfilter verwenden"
        {...form.getInputProps('useWithDefaultFilter', { type: 'checkbox' })}
      />
      <Text size="sm" mt="md" mb={5} color="#6E6E6E">
        Vorschau
      </Text>
      <Paper shadow="md">
        <BookingStateItem active label={label} color={color} bgColor={bgColor} onClick={() => {}} icon={emoji} />
      </Paper>
      <Center mt={40}>
        <Button
          disabled={loading || !form.isDirty()}
          type="submit"
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 230 }}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </form>
  );
};

export const openDeleteEventStateModal = (
  state: CustomEventState,
  onDelete: () => void,
  allStates: CustomEventState[],
) => {
  const otherStates = allStates.filter((k) => k.id !== state.id);
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Status löschen
      </Text>
    ),
    children: <DeleteEventKindModal state={state} onDelete={onDelete} otherStates={otherStates} />,
  });
};

interface DeleteModalProps {
  state: CustomEventState;
  onDelete: () => void;
  otherStates: CustomEventState[];
}

const DeleteEventKindModal: FC<DeleteModalProps> = ({ state, onDelete, otherStates }) => {
  const { id, originState } = state;
  const [loading, setLoading] = useState(false);
  const [replace, setReplace] = useState(false);
  const [replacement, setReplacement] = useState<string>();

  const onConfirm = async () => {
    if (id) {
      setLoading(true);
      const deleted = await EventS.deleteCustomState(id, getReplacement());
      if (deleted) {
        MixpanelS.track(ConstantS.TrackingEvents.EventStateDeleted);
        onDelete();
        closeAllModals();
      }
      setLoading(false);
    }
  };

  const getReplacement = () => {
    if (replace) {
      const tmp = otherStates.find((k) => k.label === replacement);
      if (tmp) {
        return tmp.id;
      }
    }
    return undefined;
  };

  return (
    <>
      <Text>
        Möchtest du diesen Auftragstyp entfernen? Deine Events bleiben bestehen, aber der Auftragstyp wird von den
        Events entfernt.
      </Text>
      <Checkbox
        mt="xs"
        mb="xs"
        label="Der Auftragstyp soll durch einen anderen Auftragstyp ersetzt werden."
        checked={replace}
        onChange={(e) => setReplace(e.currentTarget.checked)}
      />
      {replace && (
        <Select
          value={replacement}
          onChange={(val) => val && setReplacement(val)}
          mb="md"
          label={`${i18n._(`event.state.${originState.toLocaleLowerCase()}`)} ersetzen durch`}
          data={DataS.getEventStateOptions(otherStates.filter((s) => s.originState === originState))}
        />
      )}

      <Group position="right" spacing="xs" mt="xl">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          rightIcon={loading && <Loader size="xs" />}
          color="red"
          disabled={loading || (replace && !replacement)}
          onClick={onConfirm}
        >{`${i18n._('actions.delete')}${replace ? ' und ersetzen' : ''}`}</Button>
      </Group>
    </>
  );
};
