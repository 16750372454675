// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Drawer, DrawerProps, Group, Space, Stack, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props extends DrawerProps {
  label?: string;
  icon?: ReactNode;
  description?: ReactNode;
}

const NavDrawer: FC<Props> = ({ children, title, label, icon, description, ...rest }) => (
  <Drawer
    trapFocus={false}
    withCloseButton={false}
    title={
      title ? (
        <Text pl="sm" pt="xl" weight="bolder" size="xl">
          {title}
        </Text>
      ) : undefined
    }
    size={270}
    zIndex={99999}
    styles={{
      body: {
        overflow: 'auto', // Ensures the content is scrollable
        maxHeight: '100vh', // Restricts height to the viewport
      },
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {label && icon && <NavDrawerTitle label={label} icon={icon} description={description} />}
    {children}
    <Space h={50} />
  </Drawer>
);

type TitleProps = Omit<Props, keyof DrawerProps>;

const NavDrawerTitle: FC<TitleProps> = ({ label, icon, description }) => (
  <Box
    p="md"
    sx={{
      background: 'linear-gradient(90deg, rgb(255, 92, 90) 0%, rgb(253, 166, 50) 100%)',
      color: 'white',
    }}
  >
    <Group spacing="xs" sx={{ alignItems: description ? 'flex-start' : 'center' }}>
      <div style={{ marginTop: -3 }}>{icon}</div>
      <Stack spacing={0}>
        <Text weight="bolder" size="xl">
          {label}
        </Text>
        {description}
      </Stack>
    </Group>
  </Box>
);

export default NavDrawer;
