// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import { FC } from 'react';
import { ContactGroup } from '../../Types/AuthT';
import ContactGroupActions from './ContactGroupActions';

interface Props {
  label: string;
  groups: ContactGroup[];
  closeNavbar: () => void;
}

const ContactGroupsList: FC<Props> = ({ label, groups, closeNavbar }) => {
  const mapGroupItemUI = (group: ContactGroup) => {
    const { name } = group;
    return (
      <Group
        p={5}
        position="apart"
        sx={(theme) => ({
          height: 32,
          '&:hover': {
            backgroundColor: theme.colors.gray[0],
          },
        })}
      >
        <Text ml="lg">{name}</Text>
        <ContactGroupActions group={group} closeNavbar={closeNavbar} />
      </Group>
    );
  };

  return (
    <>
      <Text p="md" size="sm" variant="text" weight="bolder">
        {label}
      </Text>
      {groups.length === 0 && (
        <Text ml="sm" italic color="dimmed" size="xs" weight="lighter">
          Keine Gruppen angelegt.
        </Text>
      )}
      {groups.map(mapGroupItemUI)}
    </>
  );
};

export default ContactGroupsList;
