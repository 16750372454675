// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack } from '@mantine/core';
import { useEffect } from 'react';
import { RoutingS } from '../../Service/RoutingS';
import EventStatesPane from '../../Molecules/Settings/EventStatesPane';

const EventStates = () => {
  useEffect(() => {
    RoutingS.changeTitle('Eigene Event-Status');
  }, []);

  return (
    <Stack mb={70} sx={{ maxWidth: 600 }}>
      <EventStatesPane />
    </Stack>
  );
};

export default EventStates;
