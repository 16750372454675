import { useContext, useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/react';
import { Alert } from 'reactstrap';
import { Icon } from '../Atoms/Icons';
import TooltipButton from '../Atoms/buttons/TooltipButton';
import CalendarModal from '../Atoms/Calendar/CalendarModal';
import { CheckLicence } from '../Organismns/Upgrade/CheckLicence';
import { WindowContext } from '../Service/Context/WindowContext';
import { getPrivateAndBusinessEvents } from '../Service/restapi/calendarAccountService';
import { useModalState } from './Dialogs';
import { Skeleton } from '@mantine/core';
import { EventContext } from '../Service/Context/EventContext';

export default function () {
  const [privateEvents, setPrivateEvents] = useState([]);
  const [businessEvents, setBusinessEvents] = useState([]);
  const { show, toggle } = useModalState(false);
  const [isLoading, setLoading] = useState(false);
  const { windowWidth } = useContext(WindowContext);
  const [bookedForThisEvent, setBookedForThisEvent] = useState(undefined);
  const [doubleBooking, setDoubleBooking] = useState(undefined);

  const { event, dates } = useContext(EventContext);
  const { id: eventId } = event;
  const regularDates = useMemo(() => dates.filter((ed) => ed.type === 'REGULAR_DATE'), [dates]);

  useEffect(() => {
    if (regularDates.length > 0) {
      fetchOtherEvents();
    }
  }, [regularDates]);

  function fetchOtherEvents() {
    setLoading(true);
    setDoubleBooking(undefined);
    setBookedForThisEvent(undefined);
    getPrivateAndBusinessEvents(regularDates, null)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((calendarEvents) => {
            const bookedEvents = calendarEvents.businessEvents.filter((b) => b.bookingState === 'BOOKED');
            if (bookedEvents.length > 1) {
              setDoubleBooking(true);
            }
            const a = calendarEvents.businessEvents.filter((b) => b.id === eventId && b.bookingState === 'BOOKED');
            if (a.length === 1) {
              setBookedForThisEvent(true);
            }
            const bizEvents = calendarEvents.businessEvents.filter((e) => e.id !== eventId);
            setPrivateEvents(calendarEvents.privateEvents);
            setBusinessEvents(bizEvents);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (regularDates.length === 0) {
    return null;
  }

  if (isLoading) {
    return <Skeleton width={'100%'} height={50} />;
  }
  const openBusinessEvents = businessEvents.filter((e) => e.bookingState === 'OPEN');
  const offeredBusinessEvents = businessEvents.filter((e) => e.bookingState === 'OFFERED');
  const bookedBusinessEvents = businessEvents.filter((e) => e.bookingState === 'BOOKED');

  const styles = {
    alert: {
      backgroundColor:
        bookedBusinessEvents.length > 0 || privateEvents.length > 0
          ? '#f073730f'
          : offeredBusinessEvents.length > 0
          ? 'rgba(232, 180, 112, 0.18)'
          : openBusinessEvents.length > 0
          ? 'rgba(114, 136, 154, 0.14)'
          : 'rgba(217, 241, 217, 0.18)',
      borderColor:
        bookedBusinessEvents.length > 0 || privateEvents.length > 0
          ? '#f5c6cb'
          : offeredBusinessEvents.length > 0
          ? '#fff4d7'
          : openBusinessEvents.length > 0
          ? '#dfe5ea'
          : 'rgb(229, 229, 229)',
      boxShadow: doubleBooking ? '0 0 0 0 rgba(0, 0, 0, 1)' : 'unset',
      transform: doubleBooking ? 'scale(1)' : 'unset',
      animation: doubleBooking ? 'pulse 2s infinite' : 'unset',
    },
  };

  let color = 'success';
  let icon = 'CHECK';
  let title =
    windowWidth > 500
      ? bookedForThisEvent
        ? 'Gebucht für dieses Event'
        : 'Du bist verfügbar!'
      : bookedForThisEvent
      ? 'Gebucht!'
      : 'Verfügbar!';
  let subTitle = bookedForThisEvent
    ? 'Du bist für dieses Event gebucht.'
    : 'Du hast keine gebuchten Events oder privaten Kalender-Einträge.';

  if (openBusinessEvents.length > 0) {
    color = 'info';
    icon = 'BULB';
    title = 'Offene Anfrage';
    subTitle = 'Du hast weitere offene Anfragen für diesen Tag';
  }
  if (bookedForThisEvent && openBusinessEvents.length > 0) {
    color = 'info';
    icon = 'BULB';
    title = 'Gebucht, aber andere offene Anfrage';
    subTitle = 'Du bist für dieses Event gebucht, aber Du hast offene Anfragen';
  }
  if (offeredBusinessEvents.length > 0) {
    color = 'warning';
    icon = 'ATTENTION';
    title = 'Anderem Kunden angeboten';
    subTitle = 'Du hast diesen Tag in einem anderen Event angeboten.';
  }
  if (bookedForThisEvent && offeredBusinessEvents.length > 0) {
    color = 'warning';
    icon = 'ATTENTION';
    title = 'Gebucht, aber anderem Kunden angeboten';
    subTitle = 'Du bist für dieses Event gebucht, hast Dich aber in einem anderen Event angeboten.';
  }
  if (bookedBusinessEvents.length > 0) {
    color = 'danger';
    icon = 'ATTENTION';
    title = 'Bereits ausgebucht';
    subTitle = 'Du bist an diesem Tag bereits gebucht.';
  }
  if (privateEvents.length > 0) {
    color = 'danger';
    icon = 'ATTENTION';
    title = 'Privater Termin';
    subTitle = 'Du hast private Termine an diesem Tag.';
  }
  if (bookedBusinessEvents.length > 0 && privateEvents.length > 0) {
    color = 'danger';
    icon = 'ATTENTION';
    title = 'Ausgebucht und privater Termin';
    subTitle = 'Du bist bereits gebucht und hast einen privaten Termin.';
  }
  if (doubleBooking) {
    color = 'danger';
    icon = 'ATTENTION';
    title = 'Mehrere Buchungen!';
    subTitle = 'Achtung, Du hast mehrere Buchungen für diesen Tag!';
  }

  return (
    <Alert color={color} style={{ ...styles.alert, display: 'flex', marginRight: 10, padding: '5px 10px' }}>
      <div>
        <div style={{ fontWeight: 600 }}>
          {windowWidth > 500 && <Icon style={{ marginRight: 5 }} value={icon} />}
          <Trans id={title} />
        </div>
        {windowWidth > 800 && (
          <div className="description">
            <Trans id={subTitle} />
          </div>
        )}
      </div>
      <div style={{ marginLeft: 10, display: 'flex', flexDirection: windowWidth > 800 ? 'column' : 'row' }}>
        {windowWidth > 800 && (
          <TooltipButton
            icon="REFRESH"
            id="refresh-available-check"
            isLoading={isLoading}
            text="Aktualisieren"
            style={{ color: '#666666', fontSize: 12 }}
            onClick={fetchOtherEvents}
          />
        )}
        {windowWidth > 500 && (
          <CheckLicence
            buttonText=""
            icon="CALENDAR"
            style={{ color: '#666666', fontSize: 12 }}
            onClick={toggle}
            licence="FLOW"
            upgradeTitle="Private Kalender-Termine anzeigen"
            upgradeText={
              'bookitup prüft automatisch für Dich, ob Du einen privaten Termin eingetragen hast\n' +
              'und kannst diesen direkt hier einsehen.\n'
            }
          />
        )}
      </div>
      <CalendarModal dates={regularDates} eventId={eventId} show={show} toggle={toggle} />
    </Alert>
  );
}
