// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack } from '@mantine/core';
import { useEffect } from 'react';
import { RoutingS } from '../../Service/RoutingS';
import EventKindsPane from '../../Molecules/Settings/EventKindsPane';

const EventKinds = () => {
  useEffect(() => {
    RoutingS.changeTitle('Auftragsarten');
  }, []);

  return (
    <Stack mb={70} sx={{ maxWidth: 600 }}>
      <EventKindsPane />
    </Stack>
  );
};

export default EventKinds;
