import { AppShell } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { IconFilter, IconListCheck } from '@tabler/icons';
import { TaskListContextProvider } from '../../Service/Context/TaskListContext';
import TasksNavFilter from '../../Templates/Tasks/TasksNavFilter';
import { WindowContext } from '../../Service/Context/WindowContext';
import PageHeader from '../../Templates/Navigation/PageHeader';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import { RoutingS } from '../../Service/RoutingS';
import NavDrawer from '../../Atoms/NavDrawer';
import AllTasksList from './AllTasksList';
import AddTaskIcon from './AddTaskIcon';

const Tasks: FC = () => {
  const { sm } = useContext(WindowContext);
  const { i18n } = useLingui();
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    RoutingS.changeTitle('Aufgaben');
  }, []);

  return (
    <TaskListContextProvider>
      <AppShell
        navbar={
          sm ? undefined : (
            <DoubleNavbar>
              <TasksNavFilter />
            </DoubleNavbar>
          )
        }
        header={
          <PageHeader
            title={i18n._('common.tasks')}
            navBarOpen={drawerOpened}
            setNavBarOpen={setDrawerOpened}
            rightSection={<AddTaskIcon />}
            navBarIcon={<IconFilter onClick={() => setDrawerOpened(true)} />}
          />
        }
      >
        {sm && (
          <NavDrawer
            label="Aufgaben"
            icon={<IconListCheck size={32} />}
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
          >
            <TasksNavFilter />
          </NavDrawer>
        )}
        <AllTasksList />
      </AppShell>
    </TaskListContextProvider>
  );
};

export default Tasks;
