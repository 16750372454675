/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Checkbox, Group, Loader, Space, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { EventS } from '../../Service/EventS';

interface DeleteEventModalProps {
  eventId: string;
  onDelete: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const openDeleteEventModal = (props: DeleteEventModalProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Wirklich löschen?
      </Text>
    ),
    children: <DeleteEventModal {...props} />,
  });

const DeleteEventModal: FC<DeleteEventModalProps> = ({ eventId, onDelete }) => {
  const { i18n } = useLingui();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState<string>();

  const onConfirm = async () => {
    if (!confirmed) {
      setError('Bestätige erst die Löschung der E-Mails und Dokumente');
    } else {
      setLoading(true);
      const deleted = await EventS.deleteEvent([eventId], i18n);
      setLoading(false);
      if (deleted) {
        onDelete();
        closeAllModals();
      }
    }
  };

  return (
    <>
      <Text mb="sm">{i18n._('common.delete.event.confirm')}</Text>
      <Text mb="md" weight="bold">
        Beim Löschen des Events, werden die im Event angezeigten E-Mails und Dokumente ebenfalls gelöscht.
      </Text>
      <Checkbox
        checked={confirmed}
        onChange={() => setConfirmed(!confirmed)}
        label="Bestätige die Löschung von Dokumenten und E-Mails"
        error={error}
      />
      <Group position="right" spacing="xs" mt="xl">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          rightIcon={loading && <Loader size="xs" />}
          color="red"
          disabled={loading || !confirmed}
          onClick={onConfirm}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};
