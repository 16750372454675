import { AppShell } from '@mantine/core';
import { useContext, useState, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { IconAddressBook, IconFilter } from '@tabler/icons';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import { WindowContext } from '../../Service/Context/WindowContext';
import PageHeader from '../Navigation/PageHeader';
import { ContactsContextProvider } from '../../Service/Context/ContactsContext';
import NavDrawer from '../../Atoms/NavDrawer';
import ContactsHeadActions from './ContactsHeadActions';
import ContactsPageNavBar from './ContactsPageNavbar';
import ContactsTable from './ContactsTable';

const ContactsPage = () => {
  const { sm } = useContext(WindowContext);
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    document.title = i18n._('contacts');
  }, []);

  return (
    <ContactsContextProvider>
      <AppShell
        header={
          <PageHeader
            title={i18n._('contacts')}
            navBarOpen={drawerOpened}
            setNavBarOpen={setDrawerOpened}
            rightSection={<ContactsHeadActions />}
            navBarIcon={<IconFilter onClick={() => setDrawerOpened(true)} />}
          />
        }
        navbar={
          sm ? undefined : (
            <DoubleNavbar>
              <ContactsPageNavBar closeNavbar={() => setDrawerOpened(false)} />
            </DoubleNavbar>
          )
        }
      >
        {sm && (
          <NavDrawer
            label="Kontakte"
            icon={<IconAddressBook size={32} />}
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
          >
            <ContactsPageNavBar closeNavbar={() => setDrawerOpened(false)} />
          </NavDrawer>
        )}
        <ContactsTable />
      </AppShell>
    </ContactsContextProvider>
  );
};

export default ContactsPage;
