import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { EventContext } from './EventContext';
import { EventLocationContext } from './EventLocationContext';
import { CustomAttributesContext } from './CustomAttributeContext';
import { EventParticipationsContext } from './EventParticipationContext';
import { ContactS } from '../ContactS';

export const CustomerContext = React.createContext(undefined);

export const CustomerContextProvider = ({ mainCustomerId }) => {
  const { participations } = useContext(EventParticipationsContext);
  const [participants, setParticipans] = useState([]);
  const customer = participants.find((p) => p.id === mainCustomerId);

  useEffect(() => {
    ContactS.fetchAllParticipants(participations.map((p) => p.contactId)).then(setParticipans);
  }, [participations]);

  if (!customer) {
    return <LoadingOverlay visible overlayBlur={2} loaderProps={{ size: 'xl' }} />;
  }

  return (
    <CustomerContext.Provider
      value={{
        customer,
        participants,
        updateParticipant: (contact) => {
          setParticipans(participants.map((p) => (p.id === contact.id ? contact : p)));
        },
        changeCustomer: (patch) => {
          setCustomer((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      <Outlet />
    </CustomerContext.Provider>
  );
};

export const useEventCustomer = (id) => {
  const { participants } = useContext(CustomerContext);
  const customer = participants.find((p) => p.id === id);
  return { customer };
};

export const useEventData = () => {
  const { event } = useContext(EventContext);
  const { customer } = useContext(CustomerContext);
  const { location } = useContext(EventLocationContext);
  const { customAttributeValues } = useContext(CustomAttributesContext);
  return { event, customer, location, customAttributeValues };
};
