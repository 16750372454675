import React, {useContext, useEffect} from 'react';
import {ProfileContext} from '../../Service/Context/ProfileContext';
import PanePaper from '../../Molecules/PanePaper';
import {RoutingS} from '../../Service/RoutingS';
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";
import {Text} from "@mantine/core";

// eslint-disable-next-line import/prefer-default-export
export const GDPR = () => {
  const { profile, patchProfile } = useContext(ProfileContext);
  const gdprUrl = `https://bookitup.de/wp-content/uploads/2024/12/AVV-bookitup.pdf`;

  useEffect(() => {
    RoutingS.changeTitle('Datenschutz');
  }, [])

  function trackDownloadGdprContract() {
    MixpanelS.track(ConstantS.TrackingEvents.DownloadGdprContract);
    patchProfile({ gdprAccepted: true });
  }

  function toggleSupportAccess() {
    patchProfile({ supportAccess: !profile.supportAccess });
  }

  return (
    <div style={{ maxWidth: 500 }}>
      <PanePaper title="gdpr">
        <div>
          {/* <InputField toggle={toggleSupportAccess} name={"supportAccess"} checked={profile.supportAccess} text={<div>Ich erlaube dem Support Zugriff auf meinen Account.</div>} />
                <br/><br/> */}
          <p>
            Unseren Vertrag zur Auftragsverarbeitung nach Art. 28 EU DSGVO kannst Du unter folgendem Link herunterladen:
          </p>
          <Text component="a" onClick={() => trackDownloadGdprContract()} href="https://bookitup.de/wp-content/uploads/2024/12/AVV-bookitup.pdf" target="_blank">
            AV-Vertrag hier herunterladen
          </Text>
        </div>
      </PanePaper>
    </div>
  );
};
