import { BookingState, DocumentType } from './MessageT';

export interface LogDto {
  id: string;
  profileId: number;
  lastModified: Date;
  entityType: string;
  entityId: string;
  value: string;
  logType: string;
  calendarNote: boolean;
  description: string;
  dateTime: Date;
  durationInMinutes: number;
}

export interface BookitupNotification {
  id: string;
  createdOn: Date;
  entityType: string;
  entityId: string;
  eventId: number | null;
  occurrenceType: OccurrenceType;
  seen: Date;
  personDescription: string | null;
  eventDate: Date | null;
  eventDateFormatted?: string;
  entityLabel: string | never;
  formName: string | null;
  formDomain: string | null;
  mailSubject: string | null;
  recipient: string | null;
  value: string | null;
  timeLabel?: string;
  automated: boolean;
  publishedInBrowser: boolean;
}

export interface NotificationConfig {
  id: string;
  hideInvoiceNotPaid: boolean;
  hideOfferOverdue: boolean;
}

export const DefaultConfig = {
  id: '',
  hideInvoiceNotPaid: false,
  hideOfferOverdue: false,
};

export interface NotificationFilter {
  includeAll: boolean; // Possiblity to shown only unseen notifications
  pageNumber: number;
}

export type OccurrenceType =
  | 'MANUAL'
  | 'MAIL_OPENED'
  | 'MAIL_RECEIVED'
  | 'CONTRACT_SIGNED'
  | 'OFFER_CONFIRMED'
  | 'QUESTIONNAIRE_FILLED'
  | 'EVENT_CREATED'
  | 'EVENT_STATE_CHANGED'
  | 'REQUEST_RECEIVED'
  | 'RECOMMENDATION_MAIL_SEND'
  | 'RECOMMENDATION_REQUEST_AVAILABILITY'
  | 'RECOMMENDATION_SEND_CUSTOMER_CONTACT'
  | 'RECOMMENDATION_MAIL_RECEIVED'
  | 'RECOMMENDATION_AVAILABILITY_CHANGED'
  | 'RECOMMENDATION_STATE_CHANGED'
  | 'SCHEDULED_MAIL_FAILED'
  | 'SCHEDULED_MAIL_SUCCESSFUL'
  | 'TASK_COMPLETED'
  | 'TASK_OVERDUE'
  | 'OFFER_OVERDUE'
  | 'INVOICE_NOT_PAID'
  | 'DOCUMENT_CREATED'
  | 'WORKFLOW_COMPLETED'
  | 'MAIL_SENT'
  | 'QUESTIONNAIRE_CREATED'
  | 'PORTAL_CREATED'
  | 'ENTITY_SHARED'
  | 'CONTRACT_CREATED'
  | 'TASK_CREATED'
  | 'CONDITION_NOT_FULFILLED'
  | 'PROCEDURE_FAILED'
  | 'APPROVAL_REQUIRED'
  | 'TASK_DELETED'
  | 'TASKS_DELETED'
  | 'EXTERNAL_CALENDAR_UNREACHABLE'
  | 'TASKS_DELETED'
  | 'EXTERNAL_EMAIL_CONNECTED'
  | 'GOOGLE_ACCOUNT_DISCONNECTED';

export interface WorkflowTemplateDTO {
  id: string;
  enabled: boolean;
  name: string;
  generalWorkflow: boolean;
  uniquePerEvent: boolean;
  runAutomatically: boolean;
  kindsOfInterest: string[];
  typeOfInterest: OccurrenceType | null;
  steps: WorkflowStepTemplateDTO[];
  alternativeSteps: WorkflowStepTemplateDTO[];
  lastModified: Date;
  customerReference: EntityReferenceDTO;
}

export interface WorkflowStepTemplateDTO {
  id: string;
  procedureType: ProcedureType;
  executionConfig: ExecutionConfigDTO;
  configId: string;
  entityReference: EntityReferenceDTO;
  alternativeStep: boolean;
}

export type EntityReferenceDTO = {
  id: string;
  label: string;
  entityType: string;
};

export interface ExecutionConfigDTO {
  executionStrategy: ExecutionStrategy;
  thresholdDate: Date;
  typeOfInterest: OccurrenceType;
  relativeAmount: number;
  relativeUnit: ChronoUnit;
  entityOfInterest: EntityReferenceDTO | null;
  manualApprovalRequired: boolean;
  finishWithAlternativeStep: boolean;
}

export interface PatchWorkflowStepTemplate {
  procedureType: ProcedureType;
  executionStrategy: ExecutionStrategy;
  typeOfInterest: OccurrenceType;
  relativeUnit: ChronoUnit;
  relativeAmount: number;
  configId: string;
  entityReferenceId: string;
  manualApprovalRequired: boolean;
  finishWithAlternativeStep: boolean;
}

export type ExecutionStrategy = 'AFTER_PREVIOUS_STEP' | 'BEFORE_EVENT_START' | 'AFTER_EVENT_END' | 'WAIT_UNTIL';

export type ProcedureType =
  | 'SEND_MAIL'
  | 'CREATE_DOCUMENT'
  | 'ARCHIVE_CONTACT'
  | 'CREATE_EVENT'
  | 'CREATE_ONLINE_PORTAL'
  | 'CREATE_TASK'
  | 'UPDATE_FIELD'
  | 'CHANGE_EVENT_STATUS'
  | 'CREATE_QUESTIONNAIRE'
  | 'CREATE_SHARE'
  | 'SHARE_ENTITY'
  | 'WAIT_UNTIL'
  | 'CREATE_CONTRACT'
  | 'COMPLETE_TASK'
  | 'DELETE_TASK'
  | 'DELETE_TASKS';

export type ChronoUnit = 'MINUTES' | 'HOURS' | 'DAYS' | 'WEEKS' | 'MONTHS';

export interface WorkflowConfigProps {
  config: unknown;
  handleChange: (patch: unknown) => void;
  availableEntities?: EntityReferenceDTO[];
}

export type CreateShareConfigDTO = {
  name: string | null;
  password: string | null;
  randomPassword: boolean;
  sharedForAmount: number;
  sharedForUnit: ChronoUnit;
};

export type ShareEntityConfigDTO = {
  entityReference: EntityReferenceDTO;
  portalReference: EntityReferenceDTO;
  useExistingPortal: boolean;
  editableForAmount: number;
  editableForUnit: ChronoUnit;
  sharedForAmount: number;
  sharedForUnit: ChronoUnit;
};

export type CreateContractConfigDTO = {
  templateId: string | null;
  offerReference: EntityReferenceDTO;
};

export type CreateDocumentConfigDTO = {
  documentType: DocumentType;
  templateName: string;
  originDocument: EntityReferenceDTO;
  finalInvoice: boolean;
  partialAmount: number;
  partialAmountUnit: string;
};

export type GeneralTaskConfigDTO = {
  taskReference: EntityReferenceDTO;
};

export type SendMailConfigDTO = {
  templateId: number | null;
  receiver: 'PRIMARY_CONTACT' | 'ALL_PARTICIPANTS';
  attachments: EntityReferenceDTO[];
};

export type CreateQuestionnaireConfigDTO = {
  templateId: string | null;
};

export type ObservableOccurrenceType =
  | 'MAIL_OPENED'
  | 'MAIL_RECEIVED'
  | 'CONTRACT_SIGNED'
  | 'OFFER_CONFIRMED'
  | 'QUESTIONNAIRE_FILLED'
  | 'INVOICE_NOT_PAID';

export type WaitUntilConfigDTO = {
  occurrenceType: OccurrenceType;
  entityReference: EntityReferenceDTO;
};

export type CreateTaskConfigDTO = {
  name: string;
  saveUnderEvent: boolean;
  groupName: string;
  priority: boolean;
  notes: string;
  relativeAmount: number;
  relativeUnit: ChronoUnit;
  dueDateStrategy: ExecutionStrategy;
};

export type WorkflowTrigger = 'MANUAL' | 'AUTOMATIC' | 'ON_OCCURRENCE';

export interface WorkflowDTO {
  id: string;
  templateId: string;
  eventId: number;
  name: string;
  startedAt: Date;
  pausedAt: Date;
  abortedAt: Date;
  completedAt: Date;
  completedSteps: number;
  totalSteps: number;
  currentStep: number;
  executionStatus: ExecutionStatus;
  steps: WorkflowStepDTO[];
  finishedPrematurely: boolean;
}

export interface WorkflowStepDTO {
  id: string;
  configId: string;
  procedureType: ProcedureType;
  entityReference: EntityReferenceDTO;
  executionConfig: ExecutionConfigDTO;
  executionReport: ExecutionReport;
  alternativeStep: boolean;
  completed: boolean;
}

export interface ChangeEventStatusConfigDTO {
  bookingState: BookingState;
  customStateId: string | null;
}

export interface DeleteTasksConfigDTO {
  removeOnlyUnfinished: boolean;
}
export interface ExecutionReport {
  executionStatus: ExecutionStatus;
  executedAt: Date;
  workflowError: WorkflowError;
  entityId: string;
  completed: boolean;
}

export interface ProcedureReportProps {
  report: ExecutionReport;
  config?: unknown;
}

export type ExecutionStatus =
  | 'WAITING'
  | 'PAUSED'
  | 'COMPLETED'
  | 'FAILED'
  | 'FULFILLED'
  | 'SKIPPED'
  | 'SCHEDULED'
  | 'PRECONDITION_FAILED'
  | 'APPROVAL_REQUIRED'
  | 'ABORTED';

export type WorkflowOperation = 'START' | 'PAUSE' | 'CONTINUE' | 'RETRY' | 'APPROVE';

export type WorkflowError = 'CUSTOMER_MISSING_EMAIL' | 'UNRESOLVED_PLACEHOLDERS' | 'INVALID_PROCEDURE_CONFIG' | 'OTHER';
