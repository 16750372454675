import { ajaxActions } from './AjaxActions';
import { blobToFile } from './FileActions';
import { ToastS } from './ToastS';

export type DocumentLayoutPlacement = 'NONE' | 'RIGHT' | 'CENTER' | 'JUSTIFIED';
type DocumentLogoSize = 'EXTRA_SMALL' | 'SMALL' | 'MEDIUM' | 'BIG' | 'FULLSIZE';
type VerticalTextAlignEnum = 'TOP' | 'MIDDLE' | 'BOTTOM' | 'JUSTIFIED';
type ContactName = 'ALIAS' | 'COMPANY' | 'NONE';

const Layout = {
  LOGO_SIZE: 'logoSize',
  HIDE_LOGO: 'hideLogoOnDocuments',
  LOGO_POSITION: 'logoPosition',
  PAGE_POSITION: 'pageNumberPosition',
  COLOR: 'fontColor',
  BACKGROUND_COLOR: 'backgroundColor',
  BOX_PLACEMENT: 'infoBoxPlacement',
  SHOW_BORDER: 'showBorders',
  SHOW_LINE_NUMBER: 'showLineNumber',
  BACKGROUND_TRASPARENT: 'backgroundTransparent',
  FONT: 'font',
  CONTACT_NAME: 'contactName',
  SHOW_FOUNDER_NAME: 'showFounderPersonalName',
  HIDE_BANK_ACCOUNT: 'hideBankAccount',
};

export interface DocumentLayout {
  id: number;
  infoBoxPlacement: DocumentLayoutPlacement;
  logoSize: DocumentLogoSize;
  withContractParties: boolean;
  logoPosition: number;
  pageNumberPosition: VerticalTextAlignEnum;
  hideLogoOnDocuments: boolean;
  showBorders: boolean;
  showLineNumber: boolean;
  backgroundTransparent: boolean;
  font: string;
  fontColor: string;
  backgroundColor: string;
  headlineFontColor?: string;
  contactName?: ContactName;
  showFounderPersonalName: boolean;
  hideBankAccount: boolean;
}

const DefaultDocumentLayout: DocumentLayout = {
  id: 0,
  infoBoxPlacement: 'CENTER',
  logoSize: 'MEDIUM',
  withContractParties: false,
  logoPosition: 270,
  pageNumberPosition: 'TOP',
  hideLogoOnDocuments: false,
  showBorders: false,
  showLineNumber: true,
  backgroundTransparent: false,
  font: 'OpenSans',
  fontColor: '#000000',
  backgroundColor: '#F7F7F7',
  headlineFontColor: '#000000',
  contactName: 'COMPANY',
  showFounderPersonalName: true,
  hideBankAccount: false,
};

const BASE_URL = process.env.REACT_APP_PDF_SERVICE_URL;

const getDocumentLayout = (): Promise<DocumentLayout | null> =>
  ajaxActions.get(`${BASE_URL}/documentLayouts`).then((resp) => {
    if (resp instanceof Response && resp.ok) {
      return resp.json();
    }
    return null;
  });

const editDocumentLayout = (id: number, patch: Partial<DocumentLayout>): Promise<DocumentLayout | null> =>
  ajaxActions.patch(`${BASE_URL}/documentLayouts/${id}`, patch).then((resp) => {
    if (resp.ok) {
      return resp.json();
    }
    return null;
  });

const getLayoutMedia = async (kind: string): Promise<Blob | null> => {
  const res = await ajaxActions.getBlobResponse(`${BASE_URL}/layout/media/${kind}`);
  if (res.ok) {
    return res.blob();
  }
  return null;
};

const printDocument = (blob: Blob) => {
  const blobUrl = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = blobUrl;
  iframe.onload = () => {
    setTimeout(() => {
      if (iframe) {
        iframe.focus();
        const { contentWindow } = iframe;
        if (contentWindow) {
          contentWindow.print();
        }
      }
    }, 1);
  };
};

const generateXRechnungXML = async (
  documentId: number,
  fileName: string,
  interceptViolation?: (httpResponse: Response) => void,
) => {
  ToastS.info('xrechung-generating', 'XRechnung generieren');
  const res = await ajaxActions.get(`${BASE_URL}/document/x-rechnung?documentId=${documentId}`);
  if (res.ok) {
    const xmlFile = await res.blob();
    const finalName = fileName ? fileName.replace('.pdf', '') : '';
    blobToFile(xmlFile, null, `XRechnung ${finalName ?? ''}.xml`);
  } else if (interceptViolation) {
    interceptViolation(res);
  } else {
    ToastS.generalError();
  }
};

// eslint-disable-next-line import/prefer-default-export
export const PdfS = {
  DefaultDocumentLayout,
  Layout,
  getDocumentLayout,
  editDocumentLayout,
  printDocument,
  getLayoutMedia,
  generateXRechnungXML,
};
