/* eslint-disable react/no-unknown-property */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Divider, Menu, Skeleton, Stack, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import {
  IconCheck,
  IconCopy,
  IconDotsVertical,
  IconDownload,
  IconFileDownload,
  IconMailForward,
  IconPrinter,
  IconShare,
} from '@tabler/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useClipboard } from '@mantine/hooks';
import Moment from 'react-moment';
import { downloadDocumentContractQuestionnaire } from '../../Service/DocumentService';
import { ToastS } from '../../Service/ToastS';
import { EventPortalContext } from '../../Service/Context/EventPortalContext';
import { fetchResourceByEntityTypeAndEntityId, newShare, share } from '../../Service/restapi/shareService';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import { ShareFlow1 } from '../../Organismns/Share/ShareFlow/ShareFlow-1';
import { ModalDialog, useConfirmModalState } from '../Dialogs';
import ImportButton from '../Lexoffice/ImportButton';
import { PdfS } from '../../Service/PdfS';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import { PortalResouce } from '../../Types/ShareT';
import { ValidationS } from '../../Service/ValidationS';
import SevdeskSyncButton from '../Event/Documents/SevdeskSyncButton';

const DocumentPreviewActions: FC<{
  entityType: string;
  entity: any;
  documentFile?: Blob;
  dropDownVesion?: boolean;
  portalSupported?: boolean;
  setLastModified: (d: Date) => void;
  patchEntity?: (patch: any, onSuccess?: () => void) => void;
  isPrintable?: boolean;
}> = ({
  entityType,
  entity,
  documentFile,
  dropDownVesion,
  portalSupported,
  setLastModified,
  patchEntity,
  isPrintable = true,
}) => {
  const navigate: NavigateFunction = useNavigate();
  const { i18n } = useLingui();
  const clipboard = useClipboard({ timeout: 1000 });
  const { shares, reload } = useContext(EventPortalContext);
  const { executeWithLicenceCheck, interceptViolation } = useContext(ViolationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>();
  const [sharedUntil, setSharedUntil] = useState<Date>();
  const [name, setName] = useState<string>();
  const [portalResource, setPortalResource] = useState<PortalResouce>();
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const { id, eventId, documentType, draft, label, xrechnungRequired } = entity;
  const isInvoice = documentType && DocumentS.isInvoice(documentType);

  useEffect(() => {
    if (portalSupported) {
      fetchPortalResource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clipboard.copied) {
      ToastS.info('linkCopied', 'Verknüpfung kopiert');
    }
  }, [clipboard.copied]);

  const createShare = () => {
    setLoading(true);
    const requestBody = [
      { entityType, entityId: id, label: label ?? i18n._(`resource.entityType.${entityType.toLowerCase()}`) },
    ];

    if (shares.length > 0) {
      share(shares[0].id, { entityTypeAndId: requestBody })
        .then((resp) => {
          if (resp.ok) {
            fetchPortalResource();
          }
        })
        .finally(() => setLoading(false));
    } else {
      newShare(name, password, sharedUntil, eventId).then((resp) => {
        if (resp.ok) {
          resp.json().then((s) => {
            share(s.id, { entityTypeAndId: requestBody })
              .then((resp) => {
                if (resp.ok) {
                  fetchPortalResource();
                  markAsReady(false);
                }
              })
              .finally(() => {
                reload();
                toggleConfirm();
                setLoading(false);
              });
          });
        }
      });
    }
  };

  const fetchPortalResource = () => {
    setLoading(true);
    fetchResourceByEntityTypeAndEntityId(entityType, id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((respJSON: PortalResouce[]) => {
            if (respJSON.length > 0) {
              setPortalResource(respJSON[0]);
            }
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const printDocument = () => {
    if (documentFile) {
      PdfS.printDocument(documentFile);
    } else {
      ToastS.info('loading-document', 'Datei laden');
    }
  };
  const downloadDocument = () =>
    downloadDocumentContractQuestionnaire(
      entityType,
      id,
      i18n,
      () => patchEntity && patchEntity({ draft: false }),
      interceptViolation,
    );

  const sentDocumentByMail = () => {
    if (eventId) {
      navigate(`/events/${eventId}/emails/inbox?entityType=${entityType}&entityId=${id}`);
    } else {
      navigate(`/postbox/inbox?entityType=${entityType}&entityId=${id}`);
    }
  };

  const markAsReady = (withRedirect = true) => {
    if (patchEntity) {
      patchEntity({ draft: false }, () => {
        ToastS.success('document-finished', 'Dokument wurde erfolgreich abgeschlossen');
        if (withRedirect) {
          if (eventId) {
            navigate(`/events/${eventId}/documents`);
          } else {
            navigate(`/documents`);
          }
        }
      });
    }
  };

  const downloadXRechnung = () => PdfS.generateXRechnungXML(entity.id, entity.fileName, interceptViolation);

  return (
    <div>
      <ModalDialog
        header="Portal-Zugang erstellen"
        body={
          <ShareFlow1
            name={name}
            setName={setName}
            password={password}
            setPassword={setPassword}
            sharedUntil={sharedUntil}
            setSharedUntil={setSharedUntil}
          />
        }
        footer={
          <>
            <TransButtonDefault onClickHandler={toggleConfirm} text="actions.cancel" />
            <TransButtonPrimary
              disabled={!ValidationS.validatePortalPassword(password ?? '')}
              onClickHandler={createShare}
              text="actions.save"
            />
          </>
        }
        show={showConfirm}
        toggle={toggleConfirm}
      />
      {dropDownVesion ? (
        <Menu withArrow shadow="md" width={250} transition="scale-y" position="bottom-end">
          <Menu.Target>
            <div>
              <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {isInvoice && (
              <>
                <ImportButton dropDownVesion bookingDocument={entity} setLastModified={setLastModified} />
                <SevdeskSyncButton dropDownVesion bookingDocument={entity} setLastModified={setLastModified} />
              </>
            )}
            {portalSupported && eventId && (
              <div>
                {portalResource ? (
                  <Menu.Item
                    icon={<IconCopy size={16} />}
                    onClick={() => clipboard.copy(portalResource.digitalSignLink)}
                  >
                    Portal-Link kopieren
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    icon={<IconCopy size={16} />}
                    onClick={() =>
                      executeWithLicenceCheck(
                        LicenceS.Restrictions.PORTAL,
                        shares.length > 0 ? createShare : toggleConfirm,
                      )
                    }
                  >
                    Online freigeben
                  </Menu.Item>
                )}
              </div>
            )}
            <Menu.Item icon={<IconMailForward size={16} />} onClick={sentDocumentByMail}>
              Per E-Mail versenden
            </Menu.Item>
            <Menu.Item icon={<IconDownload size={16} />} onClick={downloadDocument}>
              Herunterladen
            </Menu.Item>
            {/*{xrechnungRequired && (*/}
            {/*  <Menu.Item icon={<IconFileDownload size={16} />} onClick={downloadXRechnung}>*/}
            {/*    XRechnung*/}
            {/*  </Menu.Item>*/}
            {/*)}*/}

            <Menu.Item icon={<IconPrinter size={16} />} onClick={printDocument}>
              Drucken
            </Menu.Item>
            {isPrintable && draft && patchEntity && (
              <Menu.Item icon={<IconCheck size={16} />} onClick={() => markAsReady()}>
                Fertigstellen
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      ) : (
        <Stack
          ml={20}
          pb={10}
          sx={(theme) => ({
            border: `1px solid ${theme.colors.gray[1]}`,
            borderRadius: 10,
            minWidth: 275,
          })}
        >
          <Text
            p="md"
            size="md"
            weight="bolder"
            color="blue"
            sx={(theme) => ({
              backgroundColor: theme.colors.lightBlue[0],
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            })}
          >
            Dokument bereitstellen
          </Text>
          {documentType && DocumentS.isInvoice(documentType) && (
            <>
              <ImportButton bookingDocument={entity} setLastModified={setLastModified} />
              <SevdeskSyncButton bookingDocument={entity} setLastModified={setLastModified} />
            </>
          )}
          {portalSupported && eventId && (
            <div>
              {loading ? (
                <Skeleton width="80%" height={40} ml={25} mt="sm" mb="sm" />
              ) : (
                <div>
                  {portalResource ? (
                    <>
                      <IconCopy color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
                      <Button onClick={() => clipboard.copy(portalResource.digitalSignLink)}>
                        {clipboard.copied ? 'Verknüpfung kopiert' : 'Portal-Link kopieren'}
                      </Button>
                      <Text align="center" size="xs" color="dimmed" mt="xs" mb="xs">
                        <IconCheck color="green" size={16} style={{ marginRight: 5 }} />
                        am <Moment date={portalResource.lastModified} format="DD.MM.YY" /> online freigegeben
                      </Text>
                    </>
                  ) : (
                    <>
                      <IconShare color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
                      <Button
                        mb="xs"
                        onClick={() =>
                          executeWithLicenceCheck(
                            LicenceS.Restrictions.PORTAL,
                            shares.length > 0 ? createShare : toggleConfirm,
                          )
                        }
                      >
                        Online freigeben
                      </Button>
                    </>
                  )}
                </div>
              )}
              <Divider />
            </div>
          )}
          <div>
            <IconMailForward color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
            <Button onClick={sentDocumentByMail}>Per E-Mail versenden</Button>
          </div>
          <Divider />
          <div>
            <IconDownload color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
            <Button onClick={downloadDocument}>Herunterladen</Button>
          </div>
          <Divider />
          {xrechnungRequired && (
            <>
              <div>
                <IconFileDownload color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
                <Button onClick={downloadXRechnung}>XRechnung</Button>
              </div>
              <Divider />
            </>
          )}
          <div>
            <IconPrinter color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
            <Button onClick={printDocument}>Drucken</Button>
          </div>
          {isPrintable && draft && patchEntity && (
            <>
              <Divider />
              <div>
                <IconCheck color="gray" style={{ marginLeft: 15, marginRight: 15 }} />
                <Button onClick={() => markAsReady()}>Fertigstellen</Button>
              </div>
            </>
          )}
        </Stack>
      )}
    </div>
  );
};

export default DocumentPreviewActions;
