// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {
  Space,
  Center,
  Box,
  Text,
  Group,
  Anchor,
  Breadcrumbs,
  Select,
  Checkbox,
  SegmentedControl,
} from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import EventSearchAutocomplete from '../../../Organismns/Event/EventSearchAutocomplete';
import { fetchRecommendableContacts } from '../../../Service/restapi/contactGroupService';
import { WindowContext } from '../../../Service/Context/WindowContext';
import CreateEventIcon from '../../../Organismns/Eventlist/CreateEventIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { EventsContext } from '../../../Service/Context/EventsContext';
import { DataS } from '../../../Service/DataS';
import Contact from '../../../Types/Contact';
import BookingStateItem from '../BookingStateItem';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import IntroductionTour from '../../../Organismns/Tour/IntroductionTour';
import ListBookingStateSelection from './ListBookingStateSelection';
import DateIntervalSelection from './DateIntervalSelection';
import AvailabilitySelection from './AvailabilitySelection';
import ExternalCalendarsSelection from './ExternalCalendarsSelection';

const EventListNavBar = () => {
  const { sm, windowHeight } = useContext(WindowContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { filter, updateFilter, view, setView } = useContext(EventsContext);
  const {
    agencyModeActive,
    sorting,
    lastContactShare,
    filterCustomerContactShared,
    recommendationContact,
    showAppointments,
  } = filter;
  const [contacts, setContacts] = useState<Contact[]>([]);
  const contactOptions: any = [...DataS.getContactsOptions(contacts)];
  const { profile } = useContext<any>(ProfileContext);
  const [showIntroductionTour, setShowIntroductionTour] = useState<boolean>(false);

  useEffect(() => {
    fetchRecommendableContacts().then((resp) => {
      if (resp.ok) {
        resp.json().then((contacts) => {
          setContacts(contacts);
        });
      }
    });
  }, []);

  return (
    <div >
      <div style={{ height: windowHeight - (sm ? 140 : 100), overflow: 'auto' }}>
        {!sm && (
          <Group sx={{ position: 'relative' }} mt={5}>
            <Breadcrumbs>
              <Anchor p="md" size="xl" variant="text" weight="bold">
                Events
              </Anchor>
            </Breadcrumbs>
            <Box sx={{ position: 'absolute', right: 15, top: 15 }}>
              <CreateEventIcon onClickCallback={() => setShowIntroductionTour(false)} />
              {showIntroductionTour && <IntroductionTour />}
            </Box>
          </Group>
        )}
        <Box p="xs" mt={5}>
          <EventSearchAutocomplete
            withIcon
            openOnSelect
            withoutTitle
            placeholder="Events durchsuchen"
            inputVariant="filled"
          />
        </Box>
        {agencyModeActive && view === 'list' ? (
          <>
            <Box p="xs">
              <Select
                variant="filled"
                searchable
                clearable
                placeholder="Dienstleister filtern"
                value={recommendationContact ?? 'ALL'}
                data={contactOptions}
                onChange={(val: string) =>
                  val === 'ALL'
                    ? updateFilter({ recommendationContact: undefined })
                    : updateFilter({ recommendationContact: val })
                }
              />
            </Box>
            <Space h="md" />
            <Text pl={10}>Auftragsdaten</Text>
            <Space h="xs" />
            <BookingStateItem
              label="Alle"
              onClick={() => {
                updateFilter({
                  filterCustomerContactShared: !filterCustomerContactShared,
                });
                updateFilter({ lastContactShare: null });
              }}
              active={!filterCustomerContactShared}
            />
            <BookingStateItem
              label="Nur übermittelte"
              onClick={() => {
                updateFilter({ lastContactShare: true });
                updateFilter({ filterCustomerContactShared: true });
              }}
              active={lastContactShare === true}
            />
            <BookingStateItem
              label="Nur nicht übermittelte"
              onClick={() => {
                updateFilter({ lastContactShare: false });
                updateFilter({ filterCustomerContactShared: true });
              }}
              active={lastContactShare === false}
            />
          </>
        ) : (
          <>
            {profile && LicenceS.hasAccessToResourceModule(profile.id) && (
              <>
                <Text mt="md" mb="xs" pl={10}>
                  Ansicht
                </Text>
                <BookingStateItem label="Liste" onClick={() => setView('list')} active={view === 'list'} />
                <BookingStateItem label="Kalender" active={view === 'calendar'} onClick={() => setView('calendar')} />
                <BookingStateItem
                  label="Zeitleiste"
                  onClick={() =>
                    executeWithLicenceCheck(LicenceS.Restrictions.RESOURCE_PLANNING, () => setView('timeline'))
                  }
                  active={view === 'timeline'}
                />
              </>
            )}

            {view === 'list' && (
              <>
                <Space h="md" />
                <Text pl={10}>Sortieren</Text>
                <Space h="xs" />
                <BookingStateItem
                  label="Chronologisch"
                  onClick={() => updateFilter({ sorting: 'chronological' })}
                  active={sorting === 'chronological'}
                />
                <BookingStateItem
                  label="Ohne Datum"
                  onClick={() => updateFilter({ sorting: 'dateless' })}
                  active={sorting === 'dateless'}
                />
                <BookingStateItem
                  label="Letzte Anfragen"
                  onClick={() => updateFilter({ sorting: 'createdAt' })}
                  active={sorting === 'createdAt'}
                />
              </>
            )}
          </>
        )}
        {sorting === 'chronological' && view === 'list' && <DateIntervalSelection />}
        <ListBookingStateSelection />
        {!agencyModeActive && view === 'list' && (
          <>
            <Text mt="xl" pl={10}>
              Optionen
            </Text>
            <Checkbox
              mt="sm"
              ml="md"
              mb="md"
              checked={showAppointments}
              label="Termine anzeigen"
              onChange={() => updateFilter({ showAppointments: !showAppointments })}
            />
          </>
        )}
        {view === 'calendar' && <ExternalCalendarsSelection />}
        {agencyModeActive && <AvailabilitySelection />}
        <Space h="xl" />
      </div>
      {view === 'list' && (
        <div>
          <Center>
            <SegmentedControl
              color="blue"
              value={agencyModeActive ? 'AGENTUR' : 'SOLO'}
              onChange={(val) => {
                executeWithLicenceCheck(LicenceS.Restrictions.AGENCY_MODE, () =>
                  updateFilter({ agencyModeActive: val === 'AGENTUR' }),
                );
              }}
              data={[
                { label: 'Solo', value: 'SOLO' },
                { label: 'Agentur', value: 'AGENTUR' },
              ]}
              sx={{ position: 'absolute', bottom: sm ? 25 : 40 }}
            />
          </Center>
          <Space h="xl" />
        </div>
      )}
    </div>
  );
};

export default EventListNavBar;
