// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {
  Button,
  Loader,
  Text,
  Accordion,
  TextInput,
  Center,
  Slider,
  Checkbox,
  Stack,
  Select,
  Group,
} from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals } from '@mantine/modals';
import {
  IconCar,
  IconChevronRight,
  IconClock,
  IconLocation,
  IconMapPin,
  IconNote,
  IconSearch,
  IconX,
} from '@tabler/icons';
import { ContactGroup, ServiceProviderFilter } from '../../../../Types/AuthT';
import { ContactGroupS } from '../../../../Service/ContactGroupS';
import { RecommendationS } from '../../../../Service/RecommendationS';
import { RecommendationDto } from '../../../../Types/RecommendationT';
import { LocationDto } from '../../../../Types/LocationT';
import { LocationS } from '../../../../Service/LocationS';
import RecommendationGroup from '../RecommendationGroup';
import BookingEvent, { EventDate } from '../../../../Types/Event';
import { EventS } from '../../../../Service/EventS';
import { DataS } from '../../../../Service/DataS';

interface Props {
  event: BookingEvent;
  dates: EventDate[];
  appendRecommendations: (recommendations: RecommendationDto[]) => void;
  redGreenWeakness: boolean;
  availableLocations: LocationDto[];
}

const AddRecommendationModal: FC<Props> = ({
  appendRecommendations,
  event,
  dates,
  redGreenWeakness,
  availableLocations,
}) => {
  const { id: eventId } = event;
  const [loading, setLoading] = useState(false);
  const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
  const [selectedContacts, selectContacts] = useState<number[]>([]);
  const [selectedEventDates, selectEventDates] = useState<number[]>(dates.length === 1 ? [dates[0].id] : []);
  const [filter, setFilter] = useState<ServiceProviderFilter>({ searchQuery: '', radius: 300 });
  const [eventLocation, setEventLocation] = useState<LocationDto | undefined>(
    availableLocations.find((loc) => loc.useAsPrimary),
  );
  const { searchQuery, radius } = filter;

  useEffect(() => {
    setLoading(true);
    ContactGroupS.fetchAll(true)
      .then(setContactGroups)
      .finally(() => setLoading(false));
  }, []);

  const toggleContact = (contactId: number) => {
    const remove = (id: number) => selectContacts(selectedContacts.filter((_id) => _id !== id));
    const append = (id: number) => selectContacts([...selectedContacts, id]);
    if (selectedContacts.includes(contactId)) {
      remove(contactId);
    } else {
      append(contactId);
    }
  };

  const toggleMultiple = (contactIds: number[], remove: boolean) => {
    if (remove) {
      selectContacts(selectedContacts.filter((id) => !contactIds.includes(id)));
    } else {
      const filteredIds = contactIds.filter((id) => !selectedContacts.includes(id));
      selectContacts([...selectedContacts, ...filteredIds]);
    }
  };

  const createRecommnedations = () => {
    if (eventId) {
      setLoading(true);
      RecommendationS.create(eventId, selectedContacts, selectedEventDates)
        .then(appendRecommendations)
        .finally(() => {
          setLoading(false);
          closeAllModals();
        });
    }
  };

  const toggleDates = (id: number) => {
    if (selectedEventDates.includes(id)) {
      selectEventDates(selectedEventDates.filter((_id) => _id !== id));
    } else {
      selectEventDates([...selectedEventDates, id]);
    }
  };

  const updateFilter = (patch: Partial<ServiceProviderFilter>) =>
    setFilter((prevFilter) => ({ ...prevFilter, ...patch }));

  const selectLocation = (locationId: string) => {
    const selectedId = Number(locationId);
    const loc = availableLocations.find((loc) => loc.id === selectedId);
    if (loc) {
      setEventLocation(loc);
    }
  };

  const getDateLabel = (eventDate: EventDate) => {
    const { date, timeFrom, timeTo, description, locationId } = eventDate;
    const location = availableLocations.find((l) => l.id === locationId);

    return (
      <Stack spacing={5}>
        <Text weight="bolder">{EventS.formatEventDate(new Date(date))} </Text>
        <Group>
          {timeFrom && timeTo && (
            <Group spacing={5}>
              <IconClock size={16} color="gray" />
              <Text color="dimmed" size="xs" weight="lighter">
                {`${timeFrom} - ${timeTo}`}
              </Text>
            </Group>
          )}
          {location && (
            <Group spacing={5}>
              <IconMapPin size={16} color="grey" />
              <Text color="dimmed" size="xs" weight="lighter" className="overflow-ellipsis">
                {LocationS.getDescription(location)}
              </Text>
            </Group>
          )}

          {description && (
            <Group spacing={5}>
              <IconNote size={16} color="gray" />
              <Text color="dimmed" size="xs" weight="lighter">
                {description}
              </Text>
            </Group>
          )}
        </Group>
      </Stack>
    );
  };

  return (
    <>
      {dates.length > 1 && (
        <>
          <Text mt="xl" mb="md">
            Termine für Dienstleister
          </Text>
          <Stack spacing="xs" mb="md">
            {dates.map((eventDate) => {
              const { id } = eventDate;
              return (
                <Checkbox
                  label={getDateLabel(eventDate)}
                  key={id}
                  defaultChecked={selectedEventDates.includes(id)}
                  onChange={() => toggleDates(id)}
                />
              );
            })}
          </Stack>
        </>
      )}
      {availableLocations.length > 1 && (
        <Select
          icon={<IconLocation size={20} />}
          mb="sm"
          label="Location"
          data={DataS.getLocationOptions(availableLocations)}
          onChange={(val) => val && selectLocation(val)}
          value={eventLocation ? eventLocation.id.toString() : null}
        />
      )}
      {eventLocation && LocationS.isFilled(eventLocation) && (
        <>
          <Text mt="md">Entfernung für Dienstleister</Text>
          <Slider
            mt="md"
            mb={40}
            thumbChildren={<IconCar size="1rem" />}
            thumbSize={26}
            max={300}
            value={radius}
            onChangeEnd={(val) => updateFilter({ radius: val })}
            label={(value) => `${value} km`}
            marks={[
              { value: 50, label: '50 km' },
              { value: 100, label: '100 km' },
              { value: 150, label: '150 km' },
              { value: 200, label: '200 km' },
              { value: 250, label: '250 km' },
              { value: 300, label: '> 300 km' },
            ]}
            step={50}
            styles={{
              thumb: { borderWidth: 2, padding: 3 },
              markLabel: { fontSize: 10, marginTop: 10, color: 'black' },
            }}
          />
        </>
      )}
      <TextInput
        placeholder="Dienstleister finden…"
        icon={<IconSearch size={16} color="black" />}
        onChange={(e) => updateFilter({ searchQuery: e.currentTarget.value })}
        value={searchQuery}
        rightSection={
          searchQuery.length > 0 && (
            <IconX size={16} onClick={() => updateFilter({ searchQuery: '' })} style={{ cursor: 'pointer' }} />
          )
        }
      />
      {contactGroups.length > 0 && (
        <Accordion
          mt={5}
          variant="filled"
          chevronPosition="left"
          defaultValue={contactGroups[0].name}
          chevron={<IconChevronRight size="1rem" />}
          styles={{
            chevron: {
              '&[data-rotate]': {
                transform: 'rotate(90deg)',
              },
            },
          }}
        >
          {contactGroups.map((group) => (
            <RecommendationGroup
              key={group.id}
              filter={filter}
              eventId={eventId}
              selectedEventDates={selectedEventDates}
              group={group}
              eventLocation={eventLocation}
              toggleContact={toggleContact}
              selectedContacts={selectedContacts}
              toggleMultiple={toggleMultiple}
              redGreenWeakness={redGreenWeakness}
            />
          ))}
        </Accordion>
      )}
      <Text align="center" size="xs" color="dimmed">
        Wähle alle Dienstleister aus, die Du für das Event anfragen möchtest.
      </Text>
      <Center>
        <Button
          mt="xl"
          mb="xs"
          onClick={createRecommnedations}
          disabled={loading || selectedContacts.length === 0 || selectedEventDates.length === 0}
          leftIcon={loading && <Loader size="xs" />}
        >
          Zum Event hinzufügen
        </Button>
      </Center>
      <Text size="xs" color="dimmed" align="center">
        Es werden noch keine Anfragen an Dienstleister gesendet.
      </Text>
    </>
  );
};

export default AddRecommendationModal;
