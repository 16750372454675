// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Divider, Flex, Group, Stack, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { FC, ReactNode } from 'react';
import { replaceTemplatePlaceholdersWithValues } from '../../../Service/Placeholder/PlaceholderResover';
import { Position } from '../../../Service/PositionS';
import { translate } from '../../../Service/PlaceholderTranslationService';
import { FormatS } from '../../../Service/FormatS';
import { TaxType } from '../../../Templates/Documents/DocumentS';
import ResourcePositionAssignment from '../../../Organismns/Settings/Documents/Positions/ResourcePositionAssignment';

export const ProductPositionPreview: FC<{ position: Position; favoritePosition: boolean }> = ({
  position,
  favoritePosition,
}) => {
  const { i18n } = useLingui();
  const { label, subtext, amount: quantity } = position;
  const integerQuantity = parseInt(quantity, 10);
  const isSummable = !Number.isNaN(integerQuantity);

  const translatedLabel =
    !label || label === '' ? 'Unbenannt' : translate.toLocale(label, false, false, true, i18n, 'de', () => null, {});

  const translatedDescription =
    !subtext || subtext === ''
      ? 'Noch nichts hinzugefügt ...'
      : translate.toLocale(subtext, false, false, true, i18n, 'de', () => null, {});

  const translatedQuantity = isSummable
    ? integerQuantity
    : translate.toLocale(quantity, false, false, true, i18n, 'de', () => null, {});

  return (
    <PositionPreview
      summable={isSummable}
      title={translatedLabel}
      description={translatedDescription}
      position={position}
      quantity={translatedQuantity}
      favoritePosition={favoritePosition}
    />
  );
};

export const DocumentPositionPreview: FC<{
  position: Position;
  taxType?: TaxType;
  event: any;
  document: any;
  location: any;
  customer: any;
}> = ({ position, taxType, event, document, location, customer }) => {
  const { i18n } = useLingui();
  // TODO: Rework with typescript already
  const { label, subtext, amount } = position;

  const integerQuantity = parseInt(amount, 10);
  const isSummable = !Number.isNaN(integerQuantity);

  const translatedQuantity = isSummable
    ? integerQuantity
    : translate.toLocale(amount, false, false, true, i18n, 'de', () => null, {});

  const placeholderLabel = label
    ? replaceTemplatePlaceholdersWithValues(i18n, label, {
        event,
        _document: document,
        eventLocation: location,
        customer,
      })
    : 'Unbenannt';
  const placeholderSubtext = subtext
    ? replaceTemplatePlaceholdersWithValues(i18n, subtext, {
        event,
        _document: document,
        eventLocation: location,
        customer,
      })
    : 'Noch nichts hinzugefügt ...';

  return (
    <PositionPreview
      summable
      taxType={taxType}
      title={translate.toLocale(placeholderLabel, false, false, true, i18n, 'de', () => null, {})}
      description={translate.toLocale(placeholderSubtext, false, false, true, i18n, 'de', () => null, {})}
      quantity={translatedQuantity}
      position={position}
      favoritePosition={false}
    />
  );
};

interface PositionPreviewProps {
  title: ReactNode;
  description: ReactNode;
  quantity: ReactNode;
  position: Position;
  summable?: boolean;
  taxType?: TaxType;
  favoritePosition: boolean;
}

const PositionPreview: FC<PositionPreviewProps> = ({
  title,
  description,
  quantity,
  position,
  summable,
  taxType,
  favoritePosition,
}) => {
  const { priceUnit, netPriceUnit, discount, price, tax, unit, netPrice } = position;
  const { value: discountValue, type: discountType } = discount;
  const finalPrice = FormatS.numberToCurrency(taxType === 'net' ? netPrice : price);

  return (
    <Stack>
      <Text weight="bolder" size="xs" sx={{ width: '100%' }} className="placeholder-xs">
        {title}
      </Text>
      <Text size="xs" className="placeholder-xs">
        {description}
      </Text>
      <Flex direction="row" wrap="wrap" gap="md" rowGap="xs">
        <Text size="xs" weight="bolder">
          Menge/Einheit:{'  '}
          <Text span weight="normal" className="placeholder-xs">
            {quantity}
            {` ${summable && unit ? unit : ''}`}
          </Text>
        </Text>
        <Text size="xs" weight="bolder">
          Steuer:
          <Text span weight="normal">
            {` ${tax || '____'} %`}
          </Text>
        </Text>
        <Text size="xs" weight="bolder">
          Stückpreis:
          <Text span weight="normal">
            {` ${FormatS.numberToCurrency(taxType === 'net' ? netPriceUnit : priceUnit)}`}
          </Text>
        </Text>
        {discountValue > 0 && (
          <Text size="xs" weight="bolder">
            Rabatt:
            <Text span weight="normal">
              {` ${discountValue} ${discountType === 'ABSOLUTE' ? '€' : '%'}`}
            </Text>
          </Text>
        )}
      </Flex>
      {summable && (
        <>
          <Divider />
          <Group position="apart">
            <Text size="xs" weight="bolder">
              Gesamt:
            </Text>
            {price && (
              <Text size="xs" weight="bolder">
                {finalPrice}
              </Text>
            )}
          </Group>
        </>
      )}
      <ResourcePositionAssignment preview position={position} favoritePosition={favoritePosition} />
    </Stack>
  );
};
