// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { AppShell } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { IconFilter, IconInbox } from '@tabler/icons';
import { WindowContext } from '../../Service/Context/WindowContext';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import { PostboxCountersProvider } from '../../Service/Context/PostboxCountersContext';
import NavDrawer from '../../Atoms/NavDrawer';
import EmailInboxNavLinks from './EmailInboxNavLinks';

const EmailInboxPage = () => {
  const { sm } = useContext(WindowContext);
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    RoutingS.changeTitle('Postfach');
  }, []);

  return (
    <PostboxCountersProvider>
      <AppShell
        header={
          <PageHeader
            title="Postfach"
            navBarOpen={drawerOpened}
            setNavBarOpen={setDrawerOpened}
            navBarIcon={<IconFilter onClick={() => setDrawerOpened(true)} />}
          />
        }
        navbar={
          sm ? undefined : (
            <DoubleNavbar>
              <EmailInboxNavLinks closeNavBar={() => setDrawerOpened(false)} />
            </DoubleNavbar>
          )
        }
      >
        {sm && (
          <NavDrawer
            label="Postfächer"
            icon={<IconInbox size={32} />}
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
          >
            <EmailInboxNavLinks closeNavBar={() => setDrawerOpened(false)} />
          </NavDrawer>
        )}
        <Outlet />
      </AppShell>
    </PostboxCountersProvider>
  );
};

export default EmailInboxPage;
