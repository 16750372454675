import { useState, useEffect } from 'react';
import { useJwtClaims } from '../Context/AuthContext';
import { BankAccountDTO } from '../../Types/AuthT';
import { ProfileS } from '../ProfileS';

// eslint-disable-next-line import/prefer-default-export
export const useBankAccount = () => {
  const { profileId } = useJwtClaims();
  const [loading, setLoading] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccountDTO | null>(null);

  useEffect(() => {
    if (profileId) {
      setLoading(true);
      ProfileS.getBankAccount(profileId)
        .then(setBankAccount)
        .finally(() => setLoading(false));
    }
  }, [profileId]);

  return { bankAccount, loading };
};
