// eslint-disable-next-line no-redeclare
import { useContext, useState } from 'react';
import { useLingui } from '@lingui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Button, Group, Loader, Stack, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import PositionListWithDocumentContext from '../../Molecules/Document/PositionList';
import DocumentSum from '../../Molecules/Document/DocumentSum';
import { EditableDocumentProperty } from '../../Atoms/EditableProperties/EditableDocumentProperty';
import { preChange } from '../../Utils/utils';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import PositionHeader from '../../Molecules/Document/Position/PositionHeader';
import BookitupSegmentedControl from '../../Atoms/BookitupSegmentedControl';
import { DataS } from '../../Service/DataS';
import UnassginedPartialInvoices from '../../Molecules/Event/Documents/invoices/UnassignedPartialInvoices';
import DocumentPartialInvoices from '../../Molecules/Event/Documents/invoices/DocumentPartialInvoices';
import { isDefined } from '../../Service/ValidationS';
import { useContact } from '../../Service/hooks/useContact';

const DocumentSkeleton = ({
  _document,
  customer,
  handleChange,
  savePatch,
  addPosition,
  event,
  location,
  distance,
  customAttributeValues,
}) => {
  const { i18n } = useLingui();
  const { ref, width } = useElementSize();
  const [loadingAddPosition, setLoadingAddPosition] = useState(false);
  const { profile } = useContext(ProfileContext);
  const { draft, taxType, partialInvoices, number, documentType, customerId } = _document;
  const { contact: documentCustomer } = useContact(customerId);
  const xrechnungRequired = documentCustomer && isDefined(documentCustomer.buyerReference);

  const isPartialInvoice = documentType === 'PARTIAL_INVOICE';
  const isFinalInvoice = partialInvoices && partialInvoices.length > 0;

  const usePreviewVersion = width < 800;

  return (
    <div ref={ref}>
      <Stack spacing="xs" mt={width < 500 ? 'sm' : 0}>
        <Group position="apart">
          <Group spacing={5}>
            <Text size="lg" weight="bolder" sx={{ display: 'flex' }}>
              {isFinalInvoice ? 'Schlussrechnung' : i18n._(`document.${documentType.toLowerCase()}`)}
              {number !== '' ? ` Nr. ${number}` : null}
            </Text>
            {draft && (
              <Text mt={2} color="dimmed">
                (Entwurf)
              </Text>
            )}
          </Group>
          {!profile.smallScaleBusiness && (
            <BookitupSegmentedControl
              value={taxType}
              color="blue"
              data={DataS.netGrossOptions}
              radius="md"
              onChange={(val) => savePatch({ taxType: val })}
              sx={{ minWidth: 160 }}
            />
          )}
        </Group>

        <EditableDocumentProperty
          patchField="headRow"
          preChange={(p) => preChange(p, i18n)}
          changeHandler={handleChange}
          entity={_document}
          editFormatter={EditableDivInput}
          labelFormatter={HtmlPlaceholderFormatter}
          customer={customer}
          _document={_document}
          event={event}
          eventLocation={location}
          customAttributeValues={customAttributeValues}
        />
        <PositionHeader documentVersion desktopVersion={!usePreviewVersion} showDiscount={!isPartialInvoice} />
        <Box>
          <DndProvider backend={HTML5Backend}>
            <PositionListWithDocumentContext
              preChange={(p) => preChange(p, i18n)}
              customer={customer}
              event={event}
              eventLocation={location}
              distance={distance}
              customAttributeValues={customAttributeValues}
            />
          </DndProvider>
        </Box>
        {!isPartialInvoice && (
          <Button
            variant="outline"
            mt="xs"
            mb="xs"
            leftIcon={loadingAddPosition && <Loader size="xs" />}
            disabled={loadingAddPosition}
            onClick={() => {
              setLoadingAddPosition(true);
              addPosition().finally(() => setLoadingAddPosition(false));
            }}
            sx={(theme) => ({ maxWidth: 150, backgroundColor: theme.colors.lightBlue[0], border: 'none' })}
          >
            Neue Position
          </Button>
        )}
        <DocumentSum
          isSmallScaleBusiness={profile.smallScaleBusiness}
          summable={_document.summable}
          isSummable={(summable) => {
            handleChange({ summable });
          }}
          positions={_document.positions}
        />
        {_document && <DocumentPartialInvoices bookitupDocument={_document} />}
        {_document && _document.documentType === 'INVOICE' && (
          <UnassginedPartialInvoices eventId={_document.eventId} documentId={_document.id} />
        )}

        <EditableDocumentProperty
          patchField="endRow"
          preChange={(p) => preChange(p, i18n)}
          changeHandler={handleChange}
          entity={_document}
          big
          editFormatter={EditableDivInput}
          labelFormatter={HtmlPlaceholderFormatter}
          customer={customer}
          _document={_document}
          event={event}
          eventLocation={location}
          customAttributeValues={customAttributeValues}
        />
      </Stack>
    </div>
  );
};

export default DocumentSkeleton;
