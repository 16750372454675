// eslint-disable-next-line no-redeclare
import { Box, Flex, Group, Select, Space, Stack, Text, TextInput } from '@mantine/core';
import { useContext } from 'react';
import { Trans, useLingui } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import { useElementSize } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';
import { addHours } from 'date-fns';
import { openModal } from '@mantine/modals';
import ContactListItemDescription from '../Organismns/Contact/ContactListItemDescription';
import { DocumentContext } from '../Service/Context/DocumentContext';
import { Icon } from '../Atoms/Icons';
import ContactProviderHeader from '../Organismns/Contact/ContactProviderHeader';
import { DataS } from '../Service/DataS';
import 'dayjs/locale/de';
import { ToastS } from '../Service/ToastS';
import { EventParticipationsContext } from '../Service/Context/EventParticipationContext';
import { DocumentS } from '../Templates/Documents/DocumentS';
import DocumentChangeCustomerModal from './Document/DocumentChangeCustomerModal';
import EInvoiceViolationAlert from './Document/EInvoiceValidationAlert';

const DocumentHeadData = () => {
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const { ref, width } = useElementSize();
  const { document, savePatch } = useContext(DocumentContext);
  const {
    originDocumentId,
    eventId,
    customerId,
    documentType,
    shippingType,
    date,
    validUntil,
    number,
    shippingDate,
    shippingEndDate,
  } = document;
  // const [localNumber, setLocalNumber] = useState(number);
  const hasShippingType = ['CONFIRMATION', 'INVOICE', 'PARTIAL_INVOICE'].includes(documentType);
  const fullVersion = width > 500;

  const modifyPickedDay = (_date) => addHours(_date, 2);

  const changeDocumentNo = (val) => {
    savePatch({ number: val }, null, (resp) => {
      ToastS.interceptError(resp);
    });
  };

  const eventParticipationsContext = useContext(EventParticipationsContext);

  const openChangeCustomerModal = () =>
    openModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Kunde vom Dokument ändern
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <DocumentChangeCustomerModal
          patchDocument={savePatch}
          eventParticipationContext={eventParticipationsContext}
          initialCustomerId={customerId}
        />
      ),
    });

  return (
    <div ref={ref}>
      {DocumentS.isInvoice(documentType) && <EInvoiceViolationAlert invoice={document} />}
      <Group position="apart" p={fullVersion ? 'xs' : 0}>
        <Box>
          <ContactProviderHeader />
          <Space h="xl" />
          <ContactListItemDescription withoutPartner contactId={document.customerId} addressField />
          <Text
            mt="xs"
            color="blue"
            size="xs"
            variant="link"
            sx={{ cursor: 'pointer' }}
            onClick={openChangeCustomerModal}
          >
            Kunde ändern
          </Text>
        </Box>
        <Box>
          <Flex wrap="wrap" direction={fullVersion ? 'column' : 'row'} gap="xs">
            <TextInput
              defaultValue={number}
              placeholder="-"
              label={<Text size="xs">{i18n._('document.no')}</Text>}
              onBlur={(e) => changeDocumentNo(e.currentTarget.value)}
              sx={{ maxWidth: 125 }}
            />
            <DatePicker
              required
              clearable={false}
              label={
                <Text span size="xs">
                  Datum
                </Text>
              }
              placeholder="-"
              value={new Date(date)}
              onChange={(val) => val && savePatch({ date: modifyPickedDay(val) })}
              sx={{ maxWidth: 125 }}
            />
            {documentType === 'OFFER' && (
              <DatePicker
                clearable={false}
                label={<Text size="xs">{i18n._('document.validuntil')}</Text>}
                placeholder="-"
                value={validUntil ? new Date(validUntil) : validUntil}
                minDate={new Date(date)}
                onChange={(val) => val && savePatch({ validUntil: modifyPickedDay(val) })}
                sx={{ maxWidth: 125 }}
              />
            )}
            {DocumentS.isInvoice(documentType) && (
              <DatePicker
                required
                clearable={false}
                label={
                  <Text span size="xs">
                    {i18n._('document.payuntil')}
                  </Text>
                }
                placeholder="-"
                value={validUntil ? new Date(validUntil) : validUntil}
                minDate={new Date(date)}
                onChange={(val) => val && savePatch({ validUntil: modifyPickedDay(val) })}
                sx={{ maxWidth: 125 }}
              />
            )}
          </Flex>
        </Box>
      </Group>
      {hasShippingType && (
        <Flex mt={fullVersion ? 0 : 'sm'} justify={fullVersion ? 'flex-end' : 'flex-start'}>
          <Stack>
            <Select
              label={<Text size="xs">Versandart</Text>}
              data={DataS.shippingTypeOptions}
              value={shippingType}
              onChange={(val) => val && savePatch({ shippingType: val })}
            />
            {shippingType !== 'none' && (
              <Group position="apart">
                <DatePicker
                  required
                  label={
                    <Text span size="xs">
                      Liefertermin
                    </Text>
                  }
                  placeholder="-"
                  value={shippingDate ? new Date(shippingDate) : shippingDate}
                  onChange={(val) => val && savePatch({ shippingDate: modifyPickedDay(val) })}
                  sx={{ maxWidth: 125 }}
                />
                {(shippingType === 'serviceperiod' || shippingType === 'deliveryperiod') && (
                  <>
                    <div>bis</div>
                    <DatePicker
                      placeholder="-"
                      value={shippingEndDate ? new Date(shippingEndDate) : shippingEndDate}
                      onChange={(val) => val && savePatch({ shippingEndDate: modifyPickedDay(val) })}
                      sx={{ maxWidth: 125 }}
                    />
                  </>
                )}
              </Group>
            )}
          </Stack>
        </Flex>
      )}
      {originDocumentId && (
        <Text mt="xl" align="right">
          <Icon value="INFO" style={{ color: 'orange', paddingRight: 5 }} />
          Diese <Trans id={`document.${document.documentType.toLowerCase()}`} /> basiert auf{'  '}
          <span
            style={{ textDecoration: 'underline', color: '#8199AC', cursor: 'pointer' }}
            onClick={() => {
              const targetLink = eventId
                ? `/events/${eventId}/documents/${originDocumentId}`
                : `/documents/${originDocumentId}`;
              navigate(targetLink);
            }}
          >
            Dokument
          </span>
        </Text>
      )}
    </div>
  );
};

export default DocumentHeadData;
