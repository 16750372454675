// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text, Button, Loader, ThemeIcon } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useElementSize } from '@mantine/hooks';
import { IconArrowLeft, IconEye } from '@tabler/icons';
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { getUnresolvablePlaceholders } from '../Service/restapi/documentService';
import { ToastS } from '../Service/ToastS';
import { DocumentContext } from '../Service/Context/DocumentContext';
import { TestS } from '../TestS';

const DocumentHeadTemplate: FC<{
  previewUrl: string;
  title: string;
  documentActions: ReactNode;
  entityType: string;
  entityId: string;
  disablePreview?: false;
}> = ({ previewUrl, title, documentActions, entityType, entityId, disablePreview }) => {
  const documentContext = useContext<any>(DocumentContext);
  const { id: eventId } = useParams();
  const { i18n } = useLingui();
  const { ref, width } = useElementSize();
  const navigate: NavigateFunction = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [unresolvablePlaceholders, setUnresolvablePlaceholders] = useState<[]>();

  const showPreview = () => {
    if (!unresolvablePlaceholders || unresolvablePlaceholders.length > 0) {
      fetchUnresolvablePlaceholders(true);
    } else {
      navigate(previewUrl);
    }
  };

  useEffect(() => {
    if (entityType && entityId) {
      fetchUnresolvablePlaceholders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, entityId]);

  const fetchUnresolvablePlaceholders = (notification?: boolean) => {
    setLoading(true);
    getUnresolvablePlaceholders(entityType, entityId)
      .then((unresolvablePlaceholders) => {
        setUnresolvablePlaceholders(unresolvablePlaceholders);
        if (notification && unresolvablePlaceholders.length > 0) {
          ToastS.warn('placeholder.unsatisfied', i18n._('placeholder.unsatisfied'));
          unresolvablePlaceholders.forEach((placeholder: any) => {
            const translation = i18n._(placeholder, { 0: 1 });
            ToastS.warn(`unresolvable.placeholder-${translation}`, i18n._(`${translation} nicht gesetzt`));
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div ref={ref}>
      <Group position="apart">
        <Group>
          <IconArrowLeft
            color="gray"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(eventId ? `/events/${eventId}/documents` : '/documents')}
            data-testid={TestS.Navigate.Documents.TO_DOCUMENT_LIST}
          />
          {width > 500 && (
            <Text size="lg" weight="bolder">
              {title}
            </Text>
          )}
        </Group>
        <Group>
          {width > 500 ? (
            <Button
              disabled={disablePreview || isLoading || (documentContext && documentContext.negativeSum)}
              leftIcon={isLoading ? <Loader size="xs" color="white" /> : <IconEye size={20} />}
              onClick={showPreview}
              data-testid={TestS.Navigate.Documents.TO_PREVIEW}
            >
              Vorschau
            </Button>
          ) : (
            <ThemeIcon sx={{ cursor: disablePreview ? 'none' : 'pointer' }}>
              <IconEye size={20} onClick={showPreview} data-testid={TestS.Navigate.Documents.TO_PREVIEW} />
            </ThemeIcon>
          )}
          {documentActions}
        </Group>
      </Group>
    </div>
  );
};

export default DocumentHeadTemplate;
