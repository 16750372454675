// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Center, Divider, Group, Paper, Skeleton, Stack, Text, Tooltip } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconExternalLink, IconNote } from '@tabler/icons';
import { useElementSize } from '@mantine/hooks';
import { WindowContext } from '../../Service/Context/WindowContext';
import { getMantineColor } from '../../Types/Enums/BookingState';
import { BookingEventDateItem } from '../../Types/Event';
import { BookingStateIcon } from '../../Atoms/StateIcon';
import { useAppUser } from '../../Service/Context/ApplicationUserContext';
import { RoutingS } from '../../Service/RoutingS';
import { SpanDot } from '../../Atoms/Dot';
import { Contact, ContactS } from '../../Service/ContactS';
import LocationDescription from './LocationDescription';
import EventRecommendationListWrapper from './EventRecommendations/EventRecommendationListWrapper';

const EventDateItem: FC<{ bookingEventDate: BookingEventDateItem }> = ({ bookingEventDate }) => {
  const { i18n } = useLingui();
  const { redGreenWeakness } = useAppUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<Contact>();
  const { xs, sm } = useContext(WindowContext);
  const { ref, width } = useElementSize();
  const {
    eventId,
    kind,
    kindColor,
    bookingState,
    recommendations,
    customerId,
    eventState,
    start,
    weekday,
    timeFrom,
    timeTo,
    locationId,
    dateDescription,
  } = bookingEventDate;
  const eventDate = new Date(start);
  const eventDay = eventDate.getDate();
  const customerMissing = !loading && !customer;
  const color = eventState ? eventState.color : getMantineColor(bookingState);
  const stateEmoji = eventState ? eventState.emoji : null;
  const eventStateLabel = eventState ? eventState.label : i18n._(`event.state.${bookingState.toLocaleLowerCase()}`);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    ContactS.fetchById(customerId)
      .then((resJSON) => {
        if (resJSON) {
          setCustomer(resJSON);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [customerId]);

  const getCustomerDescription = () => {
    if (loading) {
      return <Skeleton height={25} width={250} />;
    }
    if (customer) {
      return ContactS.getDescription(customer);
    }
    return i18n._('errors.contact.not.synced');
  };

  const openInNewTab = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation(); // Prevent bookitup event page to be opened
    RoutingS.openInNewTab(`${process.env.REACT_APP_FRONTEND_URL}/events/${eventId}/details`);
  };

  return (
    <Stack ref={ref} spacing={5} pos="relative">
      <Paper
        shadow="xs"
        radius={10}
        p="md"
        onClick={() => navigate(`/events/${eventId}/details`)}
        sx={(theme) => ({
          cursor: customerMissing ? 'not-allowed' : 'pointer',
          opacity: customerMissing ? 0.5 : 1,
          '&:hover': {
            backgroundColor: theme.colors.lightBlue[0],
          },
        })}
      >
        <Box sx={{ display: 'flex', minHeight: 70, position: 'relative', gap: 15 }}>
          {(redGreenWeakness || stateEmoji) && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {stateEmoji && (
                <Tooltip withArrow position="left" transition="slide-left" label={eventStateLabel}>
                  <Text size="lg">{stateEmoji}</Text>
                </Tooltip>
              )}
              {!stateEmoji && redGreenWeakness && <BookingStateIcon bookingState={bookingState} size={16} />}
            </div>
          )}
          <Divider orientation="vertical" size="xl" color={color} sx={{ borderRadius: 10 }} />
          <div style={{ display: 'flex', flexDirection: sm ? 'column' : 'row', alignItems: 'center', gap: 10 }}>
            <Text size="xs" transform="uppercase" weight="lighter">
              {weekday}
            </Text>
            <Text size={30} weight="bold">{`${eventDay < 10 ? '0' : ''}${eventDay}`}</Text>
            <Text size="xs" weight="lighter">{`${i18n
              ._(`month.${eventDate.getMonth() + 1}`)
              .toUpperCase()
              .substring(0, 3)}`}</Text>
          </div>
          <Divider orientation="vertical" />
          <Stack spacing={5}>
            <Text size="xs" color="dimmed" className="overflow-ellipsis" sx={{ maxWidth: width - 200 }}>
              {kind && (
                <>
                  {kindColor && <SpanDot withoutShadow colour={kindColor} />}
                  <b style={{ marginRight: 5 }}>{kind}</b>
                </>
              )}
              <span style={{ fontWeight: 'lighter' }}>{timeFrom && timeTo && `${timeFrom} bis ${timeTo}`}</span>
            </Text>
            <Text size="md" weight="bolder" className="overflow-ellipsis" sx={{ maxWidth: width - (xs ? 200 : 250) }}>
              {getCustomerDescription()}
            </Text>
            {locationId && <LocationDescription locationId={locationId} />}
            {dateDescription && (
              <Group spacing={5}>
                <IconNote size={16} color="gray" />
                <Text
                  color="dimmed"
                  size="xs"
                  weight="lighter"
                  className="overflow-ellipsis"
                  sx={{ maxWidth: width - 200 }}
                >
                  {dateDescription}
                </Text>
              </Group>
            )}
          </Stack>
          <Center>
            <Tooltip withArrow position="left" transition="slide-left" label="Im neuen Tab öffnen">
              <Box sx={{ position: 'absolute', right: 0 }}>
                <IconExternalLink onClick={openInNewTab} />
              </Box>
            </Tooltip>
          </Center>
        </Box>
      </Paper>
      <EventRecommendationListWrapper recommendations={recommendations} profileBookingState={bookingState} />
    </Stack>
  );
};

export default EventDateItem;
