import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Editable from '../inputs/Editable';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';

/*

EditableProperty ist nen Wrapper, der die Nutzung von Editable vereinfacht.
Kümmert sich darum das die geänderte property via axios an ne definierte URL geschickt wird.

Das _ kommt von lodash

let data = {};
  _.set(data, patchField, newValue);

Das ist übrigens eine kreative Lösung, um die Feldnamen nicht hardcoden zu müssen.

Ich erzeuge damit ein Object, was patchField als Key, und newValue als Wert hat.


<EditableProperty
    dto={props.company}
    patchField={props.patchField}
    targetUrl={.urls.companyServiceUrl + "/companies/" + props.company.uuid}
  />

props.company enthält das Company Objekt wie es angezeigt wird, und props.patchField ist der Key Name des Feldes das bearbeitet werden soll. EditableProperty kümmert sich dann mit Editable zusammen darum das nen klickbarer Label draus wird, mit nem Edit Feld. Und wenn der Wert sich ändert, wirds per Patch rausgejagt.


Ach, noch was. Den "If-Unmodified-Since" Header musst du vermutlich bei dir nicht setzen, und kannst die Logik dazu rauswerfen.

Das triggert in meinem Backend die Logik zum Pessimistic Locking - der Header ist da ziemlich sprechend. -> Der Request geht nur durch, wenn die Ressource seit dem übergebenen Zeitstempel nicht verändert wurde.

Das schon, ich hab nur das entsprechende Handling im Frontend nie fertig gemacht :D Das heißt wenn du zwei Felder hintereinander bearbeiteste, knallts :D

Das Backend ist diesbzgl. fertig. Jeder PATCH Request wird mit der geupdateten Ressource (inc. neuem Zeitstempel) beantwortet.
 */
// eslint-disable-next-line import/prefer-default-export
export const EditableProperty = ({
  targetUrl,
  patchField,
  dto,
  changeHandler,
  preChange,
  placeholder,
  editFormatter,
  labelFormatter,
  name,
  changeCallbackOnly,
  ...rest
}) => {
  const { focusedNode, setFocusedNode } = useContext(FocusedNodeContext);

  return (
    <Editable
      tabIndex="0"
      value={_.get(dto, patchField)}
      onChange={(patch) => {
        console.log('changed by ', { patch });
        if (typeof preChange === 'function') {
          patch = preChange(patch);
          console.log('changed to ', { patch });
        }

        if (changeCallbackOnly && typeof changeHandler === 'function') {
          return Promise.resolve(changeHandler(patch));
        }

        return executePatch(targetUrl, dto.lastModified, patch, changeHandler, focusedNode, setFocusedNode);
      }}
      edit={editFormatter}
      label={labelFormatter}
      placeholder={placeholder}
      name={name}
      patchField={patchField}
      {...rest}
    />
  );
};

EditableProperty.propTypes = {
  targetUrl: PropTypes.string.isRequired,
  patchField: PropTypes.string.isRequired,
  dto: PropTypes.shape({
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  placeholder: PropTypes.string,
  name: PropTypes.string,
  preChange: PropTypes.func,
};

function executePatch(serviceUrl, lastModified, data, changeHandler, focusedNode, setFocusedNode) {
  return axios
    .patch(serviceUrl, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        // "If-Unmodified-Since": lastModified
      },
    })
    .then((resp) => {
      if (resp.status === 200) {
        console.log('change to response data: ', resp.data);
        changeHandler(resp.data);

        if (focusedNode) {
          console.log('remove loading from node', { focusedNode });
          // rm loading so that for other components it's clear to keep moving (e.g. send email, save draft)
          focusedNode.classList.remove('loading');
          console.log('after removing from node', { focusedNode });
          // Do not set focused node undefined, cause of sending latest version of email (verified in in chrome and safari browser)
          // ! DO NOT SET UNDEFINED setFocusedNode(undefined); DO NOT SET UNDEFINED !
        }

        return Promise.resolve();
      }
      throw resp;
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        if (error.response) {
          console.error(error.response);
          switch (error.response.data.message) {
            case 'error.input-field.parse.long':
              throw new Error(error.response.data.message);
            case 'error.input-field.invalid-iban':
              throw new Error(error.response.data.message);
            case 'error.input-field.invalid-aws-char':
              throw new Error(error.response.data.message);
          }
          console.error(error.response.data.message);
          throw new Error('error.input-field');
        } else {
          console.error(`Failed to load ${serviceUrl} because of a network error`);
          throw new Error('error.network');
        }
      }
    });
}
