// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookitupNotification } from '../../../../Types/LogT';

const GoogleAccountDisconnected: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;
  const navigate = useNavigate();

  return (
    <Text>
      Der Zugriff auf dein Google Konto
      <Text span weight="bolder">
        {` ${value} `}
      </Text>
      muss aktualisiert werden.{'  '}
      <Text
        span
        className="pointer"
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => navigate(`/settings/google?reconnect=true`)}
      >
        Klick hier
      </Text>
      , um die Verbindung wieder herzustellen.
    </Text>
  );
};

export default GoogleAccountDisconnected;
