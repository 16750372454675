import { Menu } from '@mantine/core';
import { IconBallpen, IconDotsVertical, IconTrashX } from '@tabler/icons';
import { FC, useContext } from 'react';
import { i18n } from '@lingui/core';
import { ContactsContext } from '../../Service/Context/ContactsContext';
import { ContactGroup } from '../../Types/AuthT';
import { openContactGroupDeleteModal, openContactGroupModal } from './ContactGroupsModal';

interface Props {
  group: ContactGroup;
  closeNavbar: () => void;
}

const ContactGroupActions: FC<Props> = ({ group, closeNavbar }) => {
  const { name } = group;
  const { contactGroups, updateGroups } = useContext(ContactsContext);

  return (
    <div>
      <Menu shadow="md" width={150} transition="scale-y">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            icon={<IconBallpen size={16} />}
            onClick={() => {
              closeNavbar();
              openContactGroupModal({
                group,
                onFinish: (newGroup) => updateGroups(newGroup, 'EDIT'),
                existingNames: contactGroups.map((g) => g.name).filter((s) => s !== name),
              });
            }}
          >
            {i18n._('actions.edit')}
          </Menu.Item>

          <Menu.Item
            color="red"
            onClick={() => {
              closeNavbar();
              openContactGroupDeleteModal(group, () => updateGroups(group, 'REMOVE'));
            }}
            icon={<IconTrashX size={16} />}
          >
            {i18n._('actions.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default ContactGroupActions;
