/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, LoadingOverlay, Select, Space, Stack, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconChevronDown, IconFileDescription } from '@tabler/icons';
import { EventCustomerSelect, GeneralCustomerSelect } from '../../../Atoms/inputs/CustomerSelect';
import DocumentTemplateSelect from '../../../Atoms/inputs/DocumentTemplateSelect';
import { DataS } from '../../../Service/DataS';
import { copyDocument, createDocument } from '../../../Service/restapi/documentService';
import { ToastS } from '../../../Service/ToastS';
import BookitupDocument, { BookitupDocumentType } from '../../../Types/BookitupDocument';
import { TestS } from '../../../TestS';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { hasSuitableLicence } from '../../../Service/helper/LicenceService';
import { LicenceRestriction, LicenceS } from '../../../Service/LicenceS';
import { ConstantS } from '../../../Service/ConstantS';
import { MixpanelS } from '../../../Service/MixpanelS';

interface Props {
  documentId?: number;
  initialDocumentType?: BookitupDocumentType;
  initialCustomerId?: number;
  eventParticipationContext: unknown;
  copyingDocument?: boolean;
  eventId?: number | null;
  refresh?: () => void;
  finalInvoice?: boolean;
  openLicenceModal: (licenceRestriction: LicenceRestriction) => void;
}

const CreateDocumentModal: FC<Props> = ({
  documentId,
  initialDocumentType = 'OFFER',
  initialCustomerId,
  eventParticipationContext,
  copyingDocument,
  eventId,
  refresh,
  finalInvoice,
  openLicenceModal,
}) => {
  const form = useForm({
    initialValues: {
      documentType: initialDocumentType,
      templateName: '',
      customerId: initialCustomerId,
    },
    validate: {
      customerId: (value?: number) => (value && !isNaN(value) ? null : 'Bitte Kunden auswählen'),
    },
  });
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const { documentType } = form.values;
  const [loading, setLoading] = useState<boolean>(false);
  const { licence } = useJwtClaims();

  const onSubmit = () => {
    if (documentType === 'PARTIAL_INVOICE' && !LicenceS.hasSuitableLicence('PRO', licence)) {
      openLicenceModal(LicenceS.Restrictions.SAVE_PARTIAL_INVOICE);
      return;
    }
    const { templateName } = form.values;
    if (templateName !== '' && templateName !== 'Standard' && !hasSuitableLicence('PRO', licence)) {
      openLicenceModal(LicenceS.Restrictions.MULTIPLE_DOCUMENT_TEMPLATE);
      return;
    }
    setLoading(true);

    const onCreate = (newDocument: BookitupDocument) => {
      const { id, documentType } = newDocument;
      closeAllModals();
      MixpanelS.track(ConstantS.TrackingEvents.DocumentCreated, { documentType });
      navigate(`${eventId ? `/events/${eventId}` : ''}/documents/${id}`);
      refresh?.();
    };
    const { customerId } = form.values;
    if (copyingDocument) {
      copyDocument(documentId, documentType, templateName, customerId, finalInvoice)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(onCreate);
          } else {
            ToastS.error('err', 'Dokument kann derzeit nicht erstellt werden. Bitte versuch es später.');
          }
        })
        .finally(() => setLoading(false));
    } else {
      createDocument(customerId, templateName, eventId, documentType)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(onCreate);
          } else {
            ToastS.error('err', 'Dokument kann derzeit nicht erstellt werden. Bitte versuch es später.');
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack mt="xl" sx={{ position: 'relative' }}>
        <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} overlayOpacity={0.3} />
        <Select
          icon={<IconFileDescription size={16} />}
          label="Dokumentenart"
          data={DataS.documentTypeOptions}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          rightSection={<IconChevronDown size={14} />}
          rightSectionWidth={25}
          {...form.getInputProps('documentType')}
        />
        <DocumentTemplateSelect documentType={documentType} {...form.getInputProps('templateName')} />
        {eventParticipationContext ? (
          <EventCustomerSelect
            initialValue={initialCustomerId && initialCustomerId.toString()}
            eventParticipationContext={eventParticipationContext}
            {...form.getInputProps('customerId')}
          />
        ) : (
          <GeneralCustomerSelect
            initialValue={initialCustomerId && initialCustomerId.toString()}
            {...form.getInputProps('customerId')}
          />
        )}
      </Stack>
      <Space h="md" />
      <Button fullWidth mt="xl" mb="md" type="submit" disabled={loading} data-testid={TestS.Documents.Create.SAVE}>
        {i18n._('common.apply')}
      </Button>
      <Text color="dimmed" align="center" sx={{ cursor: 'pointer' }} onClick={() => closeAllModals()}>
        {i18n._('actions.cancel')}
      </Text>
    </form>
  );
};

export default CreateDocumentModal;
