import { AppShell } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IconCalendarEvent, IconFilter } from '@tabler/icons';
import { WindowContext } from '../../Service/Context/WindowContext';
import CreateEventIcon from '../../Organismns/Eventlist/CreateEventIcon';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import NavDrawer from '../../Atoms/NavDrawer';
import EventListNavBar from './navbar/EventListNavBar';
import WelcomeHeader from './WelcomeHeader';
import EventsPageContent from './EventsPageContent';

const EventsPage = () => {
  const { sm } = useContext(WindowContext);
  const [searchParams] = useSearchParams();
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    RoutingS.changeTitle('Events');
    if (searchParams.get('__hstc')) {
      MixpanelS.track(ConstantS.TrackingEvents.OnboardingConfirmed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppShell
      header={
        <PageHeader
          title="Events"
          navBarOpen={drawerOpened}
          setNavBarOpen={setDrawerOpened}
          rightSection={<CreateEventIcon />}
          navBarIcon={<IconFilter onClick={() => setDrawerOpened(true)} />}
        />
      }
      navbar={
        !sm ? (
          <DoubleNavbar>
            <EventListNavBar />
          </DoubleNavbar>
        ) : undefined
      }
      styles={{
        main: {
          overflowX: 'hidden',
        },
      }}
    >
      {sm && (
        <NavDrawer
          label="Events"
          icon={<IconCalendarEvent size={32} />}
          opened={drawerOpened}
          onClose={() => setDrawerOpened(false)}
        >
          <EventListNavBar />
        </NavDrawer>
      )}
      <WelcomeHeader />
      <EventsPageContent />
    </AppShell>
  );
};

export default EventsPage;
