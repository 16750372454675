/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, LoadingOverlay, Select, Text } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useEffect, useState } from 'react';
import { IconCalendarEvent } from '@tabler/icons';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import BookitupDocument from '../../../Types/BookitupDocument';
import { EventS } from '../../../Service/EventS';
import BookingEvent from '../../../Types/Event';
import { DataS } from '../../../Service/DataS';
import { ToastS } from '../../../Service/ToastS';

interface ChangeEventProps {
  bookitupDocument: BookitupDocument;
  onChange: () => void;
}

// eslint-disable-next-line import/prefer-default-export
export const openAssignEventModal = (props: ChangeEventProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Event zuordnen
      </Text>
    ),
    children: <AssignEventModal {...props} />,
  });

const AssignEventModal: FC<ChangeEventProps> = ({ bookitupDocument, onChange }) => {
  const { id: documentId, eventId: initialEventId, customerId } = bookitupDocument;
  const [loading, setLoading] = useState(false);
  const [selectedEvent, selectEvent] = useState<string | null>(null); // Let's use string for better usage with Select data
  const [bookingEvents, setBookingEvents] = useState<BookingEvent[]>([]);

  const { length } = bookingEvents;
  const emptyEvents = !loading && length === 0;
  const btnDisabled = initialEventId === selectedEvent || loading;

  useEffect(() => {
    setLoading(true);
    EventS.fetchEventsByCustomerParticipations(customerId)
      .then(setBookingEvents)
      .finally(() => setLoading(false));
  }, [customerId]);

  const onConfirm = async () => {
    if (selectedEvent) {
      setLoading(true);
      const patched = await DocumentS.patchDocument(documentId, { eventId: Number(selectedEvent) });
      setLoading(false);
      if (patched) {
        ToastS.success('foo', 'Event zugewiesen');
        onChange();
        closeAllModals();
      }
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Select
        placeholder="Event wählen"
        icon={<IconCalendarEvent />}
        data={DataS.getEventOptions(bookingEvents)}
        value={selectedEvent}
        onChange={selectEvent}
        nothingFound={emptyEvents && <Text>Es wurde kein Event zu diesem Kontakt gefunden. Bitte füge den Kontakt erst einem Event hinzu.</Text>}
      />
      <Center mt="xl">
        <Button onClick={onConfirm} disabled={btnDisabled} sx={{ width: 250 }}>
          Speichern
        </Button>
      </Center>
    </>
  );
};
