// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { FC, useContext, useEffect, useState } from 'react';
import { IconSettings } from '@tabler/icons';
import { WindowContext } from '../../Service/Context/WindowContext';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import NavDrawer from '../../Atoms/NavDrawer';
import SettingsNavBar from './SettingsNavBar';

const Settings: FC = () => {
  const { sm } = useContext(WindowContext);
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    RoutingS.changeTitle('Einstellungen');
  }, []);

  return (
    <AppShell
      header={
        <PageHeader
          title="Einstellungen"
          navBarOpen={drawerOpened}
          setNavBarOpen={setDrawerOpened}
          navBarIcon={<IconSettings onClick={() => setDrawerOpened(true)} />}
        />
      }
      navbar={
        !sm ? (
          <DoubleNavbar>
            <SettingsNavBar closeNavBar={() => setDrawerOpened(false)} />
          </DoubleNavbar>
        ) : undefined
      }
    >
      {sm && (
        <NavDrawer
          label="Einstellungen"
          icon={<IconSettings size={32} />}
          opened={drawerOpened}
          onClose={() => setDrawerOpened(false)}
        >
          <SettingsNavBar closeNavBar={() => setDrawerOpened(false)} />
        </NavDrawer>
      )}
      <Outlet />
    </AppShell>
  );
};

export default Settings;
