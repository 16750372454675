import { AppShell, Group, ThemeIcon } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { IconCalendar, IconCircles, IconFilter } from '@tabler/icons';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../../Templates/Navigation/PageHeader';
import { WindowContext } from '../../Service/Context/WindowContext';
import { ResourcesContextProvider } from '../../Service/Context/ResourcesContext';
import BookitupTutorialIcon from '../../Atoms/BookitupTutorialIcon';
import NavDrawer from '../../Atoms/NavDrawer';
import ResourcesNavBar from './ResourcesNavBar';
import AddResourceIcon from './AddResourceIcon';
import { openAvailabilityModal } from './ResourceModals';

const ResourcesPage = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);
  const [drawerOpened, setDrawerOpened] = useState(false);

  useEffect(() => {
    document.title = 'Ressourcen';
  }, []);

  return (
    <ResourcesContextProvider>
      <AppShell
        header={
          <PageHeader
            title="Ressourcen"
            navBarOpen={navBarOpen}
            setNavBarOpen={setNavBarOpen}
            rightSection={
              <Group>
                <BookitupTutorialIcon
                  videoUrl="https://app.bookitup.de/academy/channels/rental?episodeId=5ych0299qm"
                  name="CreateResource"
                />
                <ThemeIcon
                  size={20}
                  style={{ cursor: 'pointer' }}
                  variant="gradient"
                  gradient={{ from: '#FEC170', to: '#FDA632', deg: 135 }}
                >
                  {/* TODO: Change icon later to clock-check */}
                  <IconCalendar onClick={openAvailabilityModal} />
                </ThemeIcon>
                <AddResourceIcon />
              </Group>
            }
            navBarIcon={<IconFilter onClick={() => setDrawerOpened(true)} />}
          />
        }
        navbar={
          sm ? undefined : (
            <DoubleNavbar>
              <ResourcesNavBar />
            </DoubleNavbar>
          )
        }
      >
        {sm && (
          <NavDrawer
            label="Ressourcen"
            icon={<IconCircles size={32} />}
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
          >
            <ResourcesNavBar />
          </NavDrawer>
        )}
        <Outlet />
      </AppShell>
    </ResourcesContextProvider>
  );
};

export default ResourcesPage;
