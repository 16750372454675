export type Violation = {
  title: string;
  description: string;
  buttonTitle: string;
  buttonHref?: string;
  externalHref?: boolean;
};

interface ViolationInterface {
  [name: string]: Violation;
}

const Violations: ViolationInterface = {
  LO_OAUTH_REVOKED: {
    title: 'lexoffice-Fehler',
    description: 'Rechnung kann nicht erstellt werden. Bitte überprüfen Sie Ihre Verbindung zum lexoffice.',
    buttonTitle: 'Einstellungen anzeigen',
    buttonHref: '/settings/bookkeeping',
  },
  LO_SETTINGS_NOT_ALIGNED: {
    title: 'Einstellungen nicht ausgerichtet',
    description:
      'Ihre Steuerpräferenzen sind nicht aufeinander abgestimmt. Bitte ändern Sie Ihre Einstellungen entsprechend.',
    buttonTitle: 'Jetzt ändern',
    buttonHref: '/settings/taxes',
  },
  LO_CONTACT_MISSING_LASTNAME: {
    title: 'Nachname ist ein Pflichtfeld',
    description: 'Bitte hinterlege den Nachnamen Deines Kunden und versuche die Übertragung der Rechnung erneut.',
    buttonTitle: 'Kontakt bearbeiten',
    buttonHref: '/contacts',
  },
  LO_CONTACT_MISSING_COMPANY_NAME: {
    title: 'Firmenname ist ein Pflichtfeld',
    description:
      'Der Kunde ist als Firma angelegt, hat aber keinen Firmennamen eingetragen. Daher kann die Rechnung nicht übertragen werden.',
    buttonTitle: 'Kontakt bearbeiten',
    buttonHref: '/contacts',
  },
  DELETE_USED_DOCUMENT: {
    title: 'Das Dokument wird verwendet',
    description:
      'Das Dokument wird von einem anderen Dokument verwendet und kann daher nicht gelöscht werden. Entferne zunächst diese Verknüpfung.',
    buttonTitle: 'Ok',
  },
  DUPLICATE_NUMBER: {
    title: 'Dokumentnummer bereits vergeben',
    description: 'Die Dokumentnummer wird bereits in einem anderen Dokument verwendet.',
    buttonTitle: 'Ok',
  },
  DELETE_PARTIAL_INVOICE_PAYMENT_WITH_EXISTING_ORIGIN: {
    title: 'Zahlung kann nicht gelöscht werden',
    description:
      'Die Zahlung kann nicht gelöscht werden, da diese Abschlagsrechnung in einer anderen Rechnung verwendet wird.',
    buttonTitle: 'Ok',
  },

  DOCUMENT_NEGATIVE_SUM_NOT_PRINTABLE: {
    title: 'Summe darf nicht negativ sein',
    description:
      'Das Dokument kann nicht erstellt werden, da es eine negative Summe hat. Bearbeite das Dokument und prüfe die Summe.',
    buttonTitle: 'Ok',
  },
  LO_API_KEY_INVALID: {
    title: 'lexoffice API-Schlüssel',
    description: 'Der angegebene API-Schlüssel ist ungültig. Bitte erstelle einen neuen Schlüssel in lexoffice.',
    buttonTitle: '',
  },
  SEVDESK_API_KEY_INVALID: {
    title: 'sevDesk API-Schlüssel',
    description: 'Der angegebene API-Schlüssel ist ungültig. Bitte erstelle einen neuen Schlüssel in sevDesk.',
    buttonTitle: '',
  },
  LO_NOT_CONNECTED: {
    title: 'Jetzt lexoffice verbinden',
    description: 'Verbinde jetzt lexoffice mit bookitup und übertrage Rechnungen mit nur einem Klick.',
    buttonTitle: 'Buchhaltungstool verbinden',
    buttonHref: '/settings/bookkeeping',
  },
  SEVDESK_NOT_CONNECTED: {
    title: 'Jetzt sevDesk verbinden',
    description: 'Verbinde jetzt sevDesk mit bookitup und übertrage Rechnungen mit nur einem Klick.',
    buttonTitle: 'Buchhaltungstool verbinden',
    buttonHref: '/settings/bookkeeping',
  },
  SEVDESK_SYNC_FAILED: {
    title: 'Fehler bei der Übertragung zu sevDesk',
    description: 'Happla! Das hat leider nicht geklappt. Bitte kontaktiere den Support, um das Problem zu beheben.',
    buttonTitle: 'Ok',
  },
  ADDON_TIER_INSUFFICIENT: {
    title: 'Deine Ressourcen sind aufgebraucht',
    description:
      'In Deinem Paket sind keine freien Ressourcen mehr. Gehe in die Einstellungen, um auf ein größeres Paket umzustellen.',
    buttonTitle: 'Jetzt umstellen',
    buttonHref: '/settings/plans',
  },
  ENTITY_REFERENCE_IN_USE: {
    title: 'Das Löschen ist fehlgeschlagen',
    description:
      'Der Schritt konnte nicht gelöscht werden. Die durch den Schritt erstellte Entitätsreferenz wird wahrscheinlich in einem anderen Schritt verwendet. Bitte lösche alle bestehenden Beziehungen und versuche es dann erneut. \n\n Sollte das Problem weiterhin bestehen, zögere bitte nicht, uns zu kontaktieren.',
    buttonTitle: 'Ok',
  },
  INVALID_TEMPLATE_CONFIG: {
    title: 'Aktivierung fehlgeschlagen',
    description:
      'Der Workflow konnte aufgrund einer ungültigen Konfiguration nicht aktiviert werden. Bitte überprüfe, ob alle Schritte richtig konfiguriert sind.',
    buttonTitle: 'Ok',
  },
  DUE_DATE_BEFORE_VOUCHER_DATE: {
    title: 'Ungültiges Zahlungsdatum',
    description:
      'Das Datum für das Zahlungsziel liegt vor dem Rechnungsdatum. Setze das Zahlungsziel auf ein Datum nach dem Rechnungsdatum.',
    buttonTitle: 'Ok',
  },
  UNRESOLVED_PLACEHOLDERS: {
    title: 'Platzhalter können nicht ersetzt werden',
    description:
      'Im ausgewählten Dokument, aus dem die Positionen übernommen werden sollen, konnten nicht alle Platzhalter ersetzt werden. Befülle die Felder in den Event-Details oder lösche betroffene Platzhalter.',
    buttonTitle: 'Ok',
  },
  WORKFLOW_STEP_EXISTS: {
    title: 'Workflow bereits in Verwendung',
    description: 'Der Schritt kann nicht gelöscht werden, da der Workflow bereits in Events verwendet wird.',
    buttonTitle: 'Ok',
  },
  INVALID_SHIPPING_OPTIONS: {
    title: 'Das Lieferdatum ist nicht gesetzt',
    description: 'Die Rechnung kann nicht fertiggestellt werden, da das Lieferdatum nicht gesetzt ist.',
    buttonTitle: 'Ok',
  },
  EVENT_DATE_USE_IN_RECOMMENDATION: {
    title: 'Event-Datum wird in Vermittlungen verwendet',
    description:
      'Das Event-Datum kann nicht entfernt oder geändert werden, da es bereits Vermittlungen für dieses Datum gibt.',
    buttonTitle: 'Ok',
  },
  SEVDESK_VOUCHER_DISCOUNT_NOT_SUPPORTED: {
    title: 'Gesamt-Rabatt wird nicht unterstützt',
    description:
      'Du hast der Rechnung einen Gesamt-Rabatt zugewiesen. Dieser wird leider von der sevDesk API nicht unterstützt. Daher kann die Rechnung nicht übertragen werden.',
    buttonTitle: 'Ok',
  },
  INVALID_X_RECHNUNG: {
    title: 'Ungültige XRechnung',
    description:
      'Es konnte keine gültige XRechnung erstellt werden. Wahrscheinlich fehlen noch Daten von Dir oder deinem Kunden, um eine gültige XRechnung zu erstellen. Bitte kontaktiere den Support.',
    buttonTitle: 'Ok',
  },
};

const DefaultViolation: Violation = {
  title: 'TBD',
  description: 'TBD',
  buttonTitle: 'TBD',
  buttonHref: 'TBD',
};

const getViolation = (name: string) => {
  const violation = Violations[name];
  if (violation) {
    return violation;
  }
  // eslint-disable-next-line no-console
  console.error(`Unable to find violation details per name: ${name}`);
  return DefaultViolation;
};

// eslint-disable-next-line import/prefer-default-export
export const ViolationS = {
  Violations,
  getViolation,
};
