/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Loader, Stack, Text, TextInput } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useForm } from '@mantine/form';
import { closeAllModals, openModal } from '@mantine/modals';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconWorldWww } from '@tabler/icons';
import validator from 'validator';
import { TestS } from '../../../TestS';
import { SemanticFormS } from '../../../Service/restapi/semanticFormService';
import ContactFormTemplateSelect from './ContactFormTemplateSelect';
import { BrowserS } from '../../../Service/BrowserS';

// eslint-disable-next-line import/prefer-default-export
export const openCreateContactFormModal = () =>
  openModal({
    modalId: 'create-contact-form-modal',
    size: 'sm',
    title: (
      <Text weight="bolder" size="xl">
        Formular erstellen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <CreateContactFormModal />,
  });

const CreateContactFormModal = () => {
  const form = useForm({
    initialValues: {
      templateId: undefined,
      name: '',
      domain: '',
    },
    validate: {
      name: (value) => (value.length > 0 ? null : 'Bitte Name eingeben'),
      domain: (value) => (validator.isFQDN(value) ? null : 'Bitte trage eine gültige Domain ein.'),
    },
  });
  const { i18n } = useLingui();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { name, domain, templateId } = form.values;
    const bookitupForm = await SemanticFormS.create({ name, domain }, templateId);
    setLoading(false);
    if (bookitupForm) {
      const { id } = bookitupForm;
      closeAllModals();
      navigate(`/settings/contact-form/${id}`);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack mt="xl" spacing="xs">
        <TextInput required label="Name" {...form.getInputProps('name')} />
        <ContactFormTemplateSelect {...form.getInputProps('templateId')} />
        <TextInput
          required
          label="Domain"
          icon={<IconWorldWww size={20} />}
          placeholder="www.djbookie.de"
          {...form.getInputProps('domain')}
          onBlur={(e) => {
            const { value } = e.currentTarget;
            if (validator.isFQDN(value)) {
              form.setValues({ domain: value });
            } else {
              // Provided value is not valid domain. Let's try to omit procotol and pathname when URL provided
              const parsedDomain = BrowserS.extractDomain(value);
              form.setValues({ domain: parsedDomain ?? value });
            }
          }}
        />
        <Text color="dimmed" mt={-5} size="xs">
          Trage hier die Webseite ein, auf der Du das Formular nutzen möchtest wie z.B. www.djbookie.de
        </Text>
      </Stack>
      <Center mt="md">
        <Button
          mt="xl"
          type="submit"
          disabled={loading || form.values.name === ''}
          rightIcon={loading && <Loader size="xs" />}
          data-testid={TestS.Documents.Create.SAVE}
          sx={{ width: 200 }}
        >
          {i18n._('common.apply')}
        </Button>
      </Center>
    </form>
  );
};
