// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, List, Text, ThemeIcon } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { IconCheck, IconFileCertificate, IconX } from '@tabler/icons';
import BookitupDocument from '../../Types/BookitupDocument';
import { useContact } from '../../Service/hooks/useContact';
import { useEventCustomer } from '../../Service/Context/CustomerContext';
import { Contact } from '../../Service/ContactS';
import { useEventScope } from '../../Service/hooks/useEventScope';
import { useXRechnungValidator } from '../../Service/hooks/useXRechnungValidator';

const EInvoiceViolationAlert: FC<{ invoice: BookitupDocument }> = ({ invoice }) => {
  const { isEventScope } = useEventScope();

  if (isEventScope) {
    return <EventViolationAlert invoice={invoice} />;
  }
  return <EventlessViolationAlert invoice={invoice} />;
};

const EventViolationAlert: FC<{ invoice: BookitupDocument }> = ({ invoice }) => {
  const { customer } = useEventCustomer(invoice.customerId);
  return <ViolationAlert invoice={invoice} customer={customer} />;
};

const EventlessViolationAlert: FC<{ invoice: BookitupDocument }> = ({ invoice }) => {
  const { contact } = useContact(invoice.customerId);
  return <ViolationAlert invoice={invoice} customer={contact} />;
};

interface Props {
  invoice: BookitupDocument;
  customer?: Contact | null;
}

const ViolationAlert: FC<Props> = ({ invoice, customer }) => {
  const navigate = useNavigate();
  const {
    valid,
    validAddress,
    validBuyerAddress,
    validTaxFields,
    validBankAccount,
    validCompanyName,
    validDocumentFields,
    buyerEmailProvided,
    negativePositionPrice,
    grossTaxType,
  } = useXRechnungValidator(invoice, customer);

  if (valid) {
    return null;
  }

  return (
    <Alert
      icon={<IconFileCertificate />}
      title="Achtung fehlende Angaben!"
      sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
      mb="sm"
    >
      <Text>
        Damit deine Rechnung{' '}
        <Text span weight="bolder">
          rechtssicher und GoBD-konform{' '}
        </Text>
        ist, muss sie folgende gesetzliche Pflichtangaben beinhalten, die du in den Unternehmenseinstellungen pflegen
        kannst:
      </Text>
      <br />
      <List
        spacing={5}
        size="xs"
        icon={
          <ThemeIcon radius="xl" size="xs">
            <IconCheck size={10} />
          </ThemeIcon>
        }
      >
        <List.Item
          sx={{ fontWeight: !validAddress ? 'bolder' : 'normal' }}
          icon={
            validAddress ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          Anschrift des leistenden Unternehmens / Unternehmers
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validBuyerAddress ? 'bolder' : 'normal' }}
          icon={
            validBuyerAddress ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          Adresse des Kunden
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validTaxFields ? 'bolder' : 'normal' }}
          icon={
            validTaxFields ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          Steuernummer und Umsatzsteuer-Identifikationsnummer{' '}
        </List.Item>

        <List.Item
          sx={{ fontWeight: !validCompanyName ? 'bolder' : 'normal' }}
          icon={
            validCompanyName ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          Firma und Geschäftsführer / Inhaber{' '}
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validBankAccount ? 'bolder' : 'normal' }}
          icon={
            validBankAccount ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          IBAN und BIC{' '}
        </List.Item>
        <List.Item
          sx={{ fontWeight: !validDocumentFields ? 'bolder' : 'normal' }}
          icon={
            validDocumentFields ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          {' '}
          Rechnungsausstellungsdatum, Fälligkeitsdatum und Lieferdatum{' '}
        </List.Item>
        <List.Item
          sx={{ fontWeight: !buyerEmailProvided ? 'bolder' : 'normal' }}
          icon={
            buyerEmailProvided ? undefined : (
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            )
          }
        >
          Die elektronische Adresse des Käufers muss angegeben werden
        </List.Item>
        {negativePositionPrice && (
          <List.Item
            sx={{ fontWeight: 'bolder' }}
            icon={
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            }
          >
            Position mit negativer Summe
          </List.Item>
        )}
        {grossTaxType && (
          <List.Item
            sx={{ fontWeight: 'bolder' }}
            icon={
              <ThemeIcon radius="xl" size="xs" color="red">
                <IconX size={10} />
              </ThemeIcon>
            }
          >
            Rechnung muss eine Netto-Rechnung sein.
          </List.Item>
        )}
      </List>
      <Button size="xs" mt="xl" onClick={() => navigate('/settings/userdata')}>
        Einstellungen vervollständigen
      </Button>
    </Alert>
  );
};

export default EInvoiceViolationAlert;
