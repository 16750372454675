import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { EventContext } from '../../Service/Context/EventContext';
import { TestS } from '../../TestS';
import { openDeleteEventModal } from './EventModals';

const EventHeadActions: FC<{ closeNavBar: () => void }> = ({ closeNavBar }) => {
  const navigate = useNavigate();
  const { event } = useContext<any>(EventContext);
  const { id: eventId } = event;
  const onDelete = () => navigate('/');
  const onClick = () => {
    closeNavBar();
    openDeleteEventModal({ eventId, onDelete });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {eventId !== '' && (
        <Button
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF', '&:hover': { color: 'white' } }}
          onClick={onClick}
          data-testid={TestS.Events.Delete.DELETE}
        >
          Event löschen
        </Button>
      )}
    </>
  );
};

export default EventHeadActions;
