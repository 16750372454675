import moment from 'moment';
import { DocumentS } from '../Templates/Documents/DocumentS';
import { DocumentFilter } from '../Types/BookitupDocument';
import { AttributeType } from '../Types/CustomAttributeT';
import { AnswerDto, QuestionDto, QuestionnaireDto, QuestionnaireTemplateDto } from '../Types/QuestionnaireT';
import { FormSubmissionDto } from '../Types/ShareT';
import { ajaxActions } from './AjaxActions';
import { DataS } from './DataS';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL;

const fetchTemplates = async (): Promise<QuestionnaireTemplateDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/questionnaires/templates`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchQuestionnaire = async (id: string): Promise<QuestionnaireDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/questionnaires/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchQuestionnaireTemplate = async (id: string): Promise<QuestionnaireTemplateDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/questionnaires/templates/id/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchQuestionnaires = async (
  filter: DocumentFilter,
  setEmptyDocuments: (val: boolean) => void,
  abortSignal: AbortSignal,
) => {
  const params = DataS.removeEmptyValues(filter);
  const query = new URLSearchParams(params as any).toString();
  const resp = await ajaxActions.get(`${BASE_URL}/questionnaires/filter?${query}`, { signal: abortSignal });
  if (resp.ok) {
    const totalCountHeader = resp.headers.get('X-Total-Count');
    if (totalCountHeader && totalCountHeader === '0') {
      setEmptyDocuments(true);
    } else {
      setEmptyDocuments(false);
    }
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const deleteQuestionnaire = async (id: number): Promise<boolean> => {
  const isQuestionnairePublished = await DocumentS.isPublished(id, 'questionnaires');
  if (isQuestionnairePublished) {
    ToastS.error('delete-document', 'Dokument kann nicht gelöscht werden, da es im Online-Portal freigegeben wurde.');
    return false;
  }
  const resp = await ajaxActions.del(`${BASE_URL}/questionnaires/${id}`);
  if (resp.ok) {
    ToastS.info('delete.document', 'Dokument wurde gelöscht');
    return true;
  }
  return false;
};

type EntityFile = {
  entityId: number;
  entityType: string;
  fileName: string;
};

const getExistingByEventId = async (eventId: number): Promise<EntityFile[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/questionnaires/existing/events/${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const editQuestion = async (questionId: string, patch: Partial<QuestionDto>): Promise<QuestionDto | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/questions/${questionId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const resolveQuestionAnswer = (formSubmission: FormSubmissionDto, question: QuestionDto): string | null => {
  const { formAttributeValues } = formSubmission;
  const { id: questionId, attributeType, additionalAnswer } = question;
  const formAttributeValue = formAttributeValues.find((val) => val.name === questionId);
  if (!formAttributeValue) {
    return null;
  }
  let answerValue = formAttributeValue.value;

  if (answerValue && attributeType === 'MULTIPLE_CHOICE') {
    answerValue = answerValue.replaceAll('##', '; ');
  }
  if (additionalAnswer) {
    const tmp = formAttributeValues.find((val) => val.name === additionalAnswer.id);
    if (tmp) {
      const { value: additionaAnswerValue } = tmp;
      if (answerValue === '') {
        answerValue = additionaAnswerValue;
      } else {
        answerValue += `, ${additionaAnswerValue}`;
      }
    }
  }
  return answerValue;
};

const formatAnswer = (attributeType: AttributeType, answer: string | number | null) => {
  if (!answer || answer === 'undefined' || answer === 'null') {
    return 'Nicht beantwortet';
  }
  if (attributeType === 'BOOLEAN') {
    return answer === 'true' ? 'Ja' : 'Nein';
  }
  if (attributeType === 'DATE') {
    const d = new Date(answer);
    return moment(d).format('MMMM D, YYYY');
  }
  if (typeof answer === 'number') {
    return answer;
  }
  return answer.replace(/(<([^>]+)>)/gi, '');
};

const moveAnswer = async (questionId: string, fromIndex: number, newIndex: number): Promise<AnswerDto[] | null> => {
  const res = await ajaxActions.patch(
    `${BASE_URL}/questions/${questionId}/move-answer?fromIndex=${fromIndex}&newIndex=${newIndex}`,
    {},
  );
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const duplicateQuestion = async (
  bundleId: string,
  questionId: string,
  index?: number,
): Promise<QuestionDto[] | null> => {
  const res = await ajaxActions.post(
    `${BASE_URL}/questionBundles/${bundleId}/${questionId}/copy${index ? `?index=${index}` : ''}`,
  );
  if (res.status === 201) {
    ToastS.success('queston-duplicated', 'Frage dupliziert');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const QuestionnaireS = {
  fetchTemplates,
  fetchQuestionnaire,
  fetchQuestionnaireTemplate,
  fetchQuestionnaires,
  deleteQuestionnaire,
  getExistingByEventId,
  editQuestion,
  resolveQuestionAnswer,
  formatAnswer,
  moveAnswer,
  duplicateQuestion,
};
