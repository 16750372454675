// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Anchor, Box, Breadcrumbs, Center, Group, Text, TextInput } from '@mantine/core';
import { IconChevronRight, IconNumber, IconUser } from '@tabler/icons';
import { useContext } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { DocumentsContext } from '../../Service/Context/DocumentsContext';
import { WindowContext } from '../../Service/Context/WindowContext';
import DocumentHeadActions from '../../Templates/Documents/DocumentHeadActions';
import BookingStateItem from '../../Templates/EventList/BookingStateItem';
import BookitupSegmentedControl from '../../Atoms/BookitupSegmentedControl';
import { DataS } from '../../Service/DataS';
import { TestS } from '../../TestS';
import ContactAutocomplete from '../Contact/ContactAutocomplete';
import Contact from '../../Types/Contact';
import { DocumentS } from '../../Templates/Documents/DocumentS';

const DocumentListNavLinks = () => {
  const { filter, changeFilter } = useContext(DocumentsContext);
  const navigate: NavigateFunction = useNavigate();
  const { sm } = useContext(WindowContext);
  const { documentType, dateRange, draft, withPayments, numberQuery } = filter;

  const updateNummberQuery = useDebouncedCallback((query: string) => changeFilter({ numberQuery: query }), 300);

  return (
    <>
      {!sm && (
        <Group sx={{ position: 'relative' }} mt={5} mb={10}>
          <Breadcrumbs separator={<IconChevronRight size={12} />}>
            <Anchor p="md" size="xl" variant="text" weight="bolder" onClick={() => navigate('/documents')}>
              Dokumente
            </Anchor>
          </Breadcrumbs>
          <Box sx={{ position: 'absolute', right: 15, top: 15 }}>
            <DocumentHeadActions />
          </Box>
        </Group>
      )}
      <Box p="xs" mt={5}>
        <ContactAutocomplete
          clearable
          variant="filled"
          icon={<IconUser size={16} />}
          setContact={(contact) => {
            if (contact) {
              const { id } = contact as Contact;
              changeFilter({ customerId: id });
            } else {
              changeFilter({ customerId: undefined });
            }
          }}
          testId={TestS.Events.Create.CONTACT_SELECT}
          placeholder="Kunde durchsuchen"
        />
      </Box>
      {documentType !== 'QUESTIONNAIRE' && (
        <Box p="xs">
          <TextInput
            variant="filled"
            icon={<IconNumber size={16} />}
            placeholder="Nummer durchsuchen"
            defaultValue={numberQuery}
            onChange={(e) => updateNummberQuery(e.currentTarget.value)}
          />
        </Box>
      )}
      <Text pl={10} mb="xs" mt="md">
        Dokument-Art
      </Text>
      <BookingStateItem
        label="Angebote"
        active={documentType === 'OFFER'}
        onClick={() => changeFilter({ documentType: 'OFFER' })}
      />
      <BookingStateItem
        label="Auftragsbestätigungen"
        active={documentType === 'CONFIRMATION'}
        onClick={() => changeFilter({ documentType: 'CONFIRMATION' })}
      />
      <BookingStateItem
        label="Abschlagsrechnung"
        active={documentType === 'PARTIAL_INVOICE'}
        onClick={() => changeFilter({ documentType: 'PARTIAL_INVOICE' })}
      />
      <BookingStateItem
        label="Rechnungen"
        active={documentType === 'INVOICE'}
        onClick={() => changeFilter({ documentType: 'INVOICE' })}
      />
      <BookingStateItem
        label="Rechnungskorrekturen"
        active={documentType === 'CANCEL'}
        onClick={() => changeFilter({ documentType: 'CANCEL' })}
      />
      <BookingStateItem
        label="Vertrag"
        active={documentType === 'CONTRACT'}
        onClick={() => changeFilter({ documentType: 'CONTRACT' })}
      />
      <BookingStateItem
        label="Fragebogen"
        active={documentType === 'QUESTIONNAIRE'}
        onClick={() => changeFilter({ documentType: 'QUESTIONNAIRE' })}
      />
      <Text pl={10} mt="xl" mb="xs">
        Datumsbereich
      </Text>
      <BookingStateItem
        label="Alle Dokumente"
        onClick={() => changeFilter({ dateRange: 'ALL_TIME' })}
        active={dateRange === 'ALL_TIME'}
      />
      <BookingStateItem
        label="Letztes Jahr"
        onClick={() => changeFilter({ dateRange: 'LAST_YEAR' })}
        active={dateRange === 'LAST_YEAR'}
      />
      <BookingStateItem
        label="Dieses Jahr"
        onClick={() => changeFilter({ dateRange: 'CURRENT_YEAR' })}
        active={dateRange === 'CURRENT_YEAR'}
      />
      <BookingStateItem
        label="Letzter Monat"
        onClick={() => changeFilter({ dateRange: 'LAST_MONTH' })}
        active={dateRange === 'LAST_MONTH'}
      />
      <BookingStateItem
        label="Dieser Monat"
        onClick={() => changeFilter({ dateRange: 'CURRENT_MONTH' })}
        active={dateRange === 'CURRENT_MONTH'}
      />
      {documentType !== 'QUESTIONNAIRE' && (
        <>
          <Text pl={10} mt="xl" mb="xs">
            Status
          </Text>
          <Center>
            <BookitupSegmentedControl
              data={DataS.documentStatusOptions}
              value={draft !== undefined ? draft.toString() : 'undefined'}
              onChange={(val) => changeFilter({ draft: val === 'undefined' ? undefined : val === 'true' })}
            />
          </Center>
        </>
      )}
      {documentType && DocumentS.isInvoice(documentType) && (
        <>
          <Text pl={10} mt="xl" mb="xs">
            Zahlung
          </Text>
          <Center>
            <BookitupSegmentedControl
              orientation="vertical"
              value={withPayments !== undefined ? withPayments.toString() : 'undefined'}
              onChange={(val) => changeFilter({ withPayments: val === 'undefined' ? undefined : val === 'true' })}
              data={DataS.documentInvoicePaymentStatusOptions}
            />
          </Center>
        </>
      )}
      {sm && <div style={{ height: 75, width: 0 }} />}
    </>
  );
};

export default DocumentListNavLinks;
