/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Contact } from '../ContactS';
import { RecommendationS } from '../RecommendationS';
import { checkForeignCalendarAvailability } from '../restapi/calendarAccountService';
import { EventDateDTO } from '../../Types/MessageT';

// eslint-disable-next-line import/prefer-default-export
export const useServiceProdiderAnswers = (contacts: Contact[], eventId: number, eventDatesIds: number[]) => {
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());

  useEffect(() => {
    if (eventDatesIds.length > 0) {
      // eslint-disable-next-line no-return-assign
      contacts.forEach((c) => (c.requestAnswer = undefined)); // Show loading annimation
      loadAnswers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, eventDatesIds]);

  const loadAnswers = async () => {
    const { length } = contacts;
    setLoading(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await loadAnswer(contacts[i]);
      setLastUpdated(new Date());
    }
    setLoading(false);
  };

  const loadAnswer = async (contact: Contact) => {
    const { id: contactId } = contact;
    const cache = await RecommendationS.lookupCacheAvailability(contactId, eventId, eventDatesIds);
    const calRes = await checkForeignCalendarAvailability(contactId, eventId, eventDatesIds);

    if (calRes.ok) {
      const calendarEvents = await calRes.json();
      const bookedBusinessEvents = calendarEvents.businessEvents.filter(
        (e: EventDateDTO) => e.bookingState === 'BOOKED',
      );
      const clashExists = calendarEvents.privateEvents.length > 0 || bookedBusinessEvents.length > 0;
      if (clashExists) {
        contact.requestAnswer = 'UNAVAILABLE';
      } else {
        contact.requestAnswer = 'AVAILABLE';
      }
    } else if (cache) {
      const { requestAnswer } = cache;
      contact.requestAnswer = requestAnswer;
    } else {
      contact.requestAnswer = 'UNKNOWN';
    }
  };
  return { loading, lastUpdated };
};
