import { useEffect, useState } from 'react';
import { Contact, ContactS } from '../ContactS';

// eslint-disable-next-line import/prefer-default-export
export const useContact = (contactId: number) => {
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<Contact | null>(null);

  useEffect(() => {
    setLoading(true);
    ContactS.fetchById(contactId)
      .then(setContact)
      .finally(() => setLoading(false));
  }, [contactId]);

  return { loading, contact };
};
